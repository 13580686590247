import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import pdfToText from 'react-pdftotext';
import Layout from '../components/Layout';
import ManagePackagingModal from '../components/ManagePackagingModal';
import EditPackagingModal from '../components/EditPackagingModal';
import AddressModal from '../components/AddressModal';

Modal.setAppElement('#root');

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
    });
};

const ShipOrders = () => {
    const [orders, setOrders] = useState({ ebay: [], squarespace: [] });
    const [error, setError] = useState(null);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [shipmentPackagingOptions, setShipmentPackagingOptions] = useState([]);
    const [isManagePackagingModalOpen, setIsManagePackagingModalOpen] = useState(false);
    const [editingPackaging, setEditingPackaging] = useState(null);
    const [newPackaging, setNewPackaging] = useState({ name: '', length: '', width: '', height: '', maxWeight: '' });
    const [orderWeights, setOrderWeights] = useState({});
    const [isAddingPackaging, setIsAddingPackaging] = useState(false);
    const [isLoadingPackagingOptions, setIsLoadingPackagingOptions] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [isLoadingOrders, setIsLoadingOrders] = useState(true);
    const [verifiedAddresses, setVerifiedAddresses] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [isProcessingPdf, setIsProcessingPdf] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({});
    const [expenseSubmitted, setExpenseSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedShipments, setSelectedShipments] = useState({});
    const [addressSearchQuery, setAddressSearchQuery] = useState('');
    const [addressSearchResults, setAddressSearchResults] = useState([]);
    const [ordersWithIssues, setOrdersWithIssues] = useState({});
    const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
    const [deliveryServices, setDeliveryServices] = useState({});

    const senderInfo = {
        name: 'SiliCreate',
        address1: '9 Pacific Place',
        suburb: 'Kilsyth',
        state: 'VIC',
        postcode: '3137',
        businessName: 'SiliCreate Pty Ltd',
        phoneNumber: '0493070980',
        email: 'contact@silicreate.com',
    };

    useEffect(() => {
        fetchOrdersAndPackaging();
        fetchShipmentPackagingOptions();
    }, []);

    useEffect(() => {
        if (pdfData) {
            const initialSelectedShipments = pdfData.Shipments.reduce((acc, shipment) => {
                acc[shipment['Order ID']] = true;
                return acc;
            }, {});
            // Set expense as selected by default
            initialSelectedShipments['expense'] = true;
            setSelectedShipments(initialSelectedShipments);
        }
    }, [pdfData]);

    useEffect(() => {
        checkOrderAddresses();
    }, [orders]);

    const checkOrderAddresses = () => {
        const issues = {};
        ['ebay', 'squarespace'].forEach(platform => {
            orders[platform].forEach(order => {
                const { suburb, state, postalCode } = order.address; // Changed from city to suburb
                if (!suburb || !state || !postalCode) {
                    issues[order.orderId] = {
                        missingSuburb: !suburb, // Changed from missingCity to missingSuburb
                        missingState: !state,
                        missingPostalCode: !postalCode
                    };
                }
            });
        });
        setOrdersWithIssues(issues);
    };

    const fetchOrdersAndPackaging = async () => {
        setIsLoadingOrders(true);
        try {
            const ordersResponse = await fetch('https://silicreate-web-giver.silicreate.workers.dev/ship-orders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('authToken'),
                },
            });

            if (!ordersResponse.ok) {
                throw new Error('Failed to fetch orders');
            }

            const ordersData = await ordersResponse.json();

            const updatedOrders = await Promise.all(
                Object.entries(ordersData).map(async ([platform, platformOrders]) => {
                    const updatedPlatformOrders = await Promise.all(
                        platformOrders.map(async (order) => {
                            const shortDesc = shortenDescription(order.orderContent);
                            const packaging = await fetchShortDescriptionPackaging(shortDesc);
                            const verifiedAddress = await verifyAddress(order.address, order.orderId);
                            
                            // Set initial delivery service based on shippingMethod
                            let initialDeliveryService = 'PP';
                            if (platform === 'ebay') {
                                initialDeliveryService = order.shippingMethod === 'AU_ExpressDelivery' ? 'EXP' : 'PP';
                            } else if (platform === 'squarespace') {
                                initialDeliveryService = order.shippingMethod.toLowerCase().includes('express') ? 'EXP' : 'PP';
                            }
                            
                            setDeliveryServices(prev => ({...prev, [order.orderId]: initialDeliveryService}));

                            return {
                                ...order,
                                shipmentPackaging: packaging || order.shipmentPackaging,
                                verifiedAddress,
                            };
                        })
                    );
                    return [platform, updatedPlatformOrders];
                })
            );

            setOrders(Object.fromEntries(updatedOrders));
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoadingOrders(false);
        }
    };

    const fetchShortDescriptionPackaging = async (shortDescription) => {
        try {
            const response = await fetch(
                `https://silicreate-web-giver.silicreate.workers.dev/short-description-packaging?shortDescription=${encodeURIComponent(
                    shortDescription
                )}`,
                {
                    headers: {
                        Authorization: localStorage.getItem('authToken'),
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch short description packaging');
            }

            const data = await response.json();
            return data.packaging;
        } catch (error) {
            console.error('Error fetching short description packaging:', error);
            return null;
        }
    };

    const fetchShipmentPackagingOptions = async () => {
        setIsLoadingPackagingOptions(true);
        try {
            const response = await fetch(
                'https://silicreate-web-giver.silicreate.workers.dev/shipment-packaging-options',
                {
                    method: 'GET',
                    headers: {
                        Authorization: localStorage.getItem('authToken'),
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch shipment packaging options');
            }

            const data = await response.json();
            const optionsWithPlaceholder = [
                { value: '', label: 'Choose An Option', disabled: true },
                ...data,
            ];
            setShipmentPackagingOptions(optionsWithPlaceholder);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoadingPackagingOptions(false);
        }
    };

    const handleOrderSelect = (orderId) => {
        setSelectedOrders((prev) =>
            prev.includes(orderId) ? prev.filter((id) => id !== orderId) : [...prev, orderId]
        );
    };

    const handleShipmentPackagingChange = (orderId, value) => {
        if (value === 'ADD_NEW_SIZE') {
            openManagePackagingModal();
        } else {
            const selectedPackaging = shipmentPackagingOptions.find((option) => option.value === value);
            const defaultWeight = selectedPackaging ? selectedPackaging.max_weight : '';

            setOrders((prev) => ({
                ...prev,
                ebay: prev.ebay.map((order) =>
                    order.orderId === orderId ? { ...order, shipmentPackaging: value } : order
                ),
                squarespace: prev.squarespace.map((order) =>
                    order.orderId === orderId ? { ...order, shipmentPackaging: value } : order
                ),
            }));

            setOrderWeights((prev) => ({
                ...prev,
                [orderId]: defaultWeight,
            }));
        }
    };

    const handleNewPackagingChange = (field, value) => {
        setNewPackaging((prev) => ({ ...prev, [field]: value }));
    };

    const handleSaveNewPackaging = async () => {
        setIsAddingPackaging(true);
        try {
            const response = await fetch(
                'https://silicreate-web-giver.silicreate.workers.dev/add-custom-shipment-packaging',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('authToken'),
                    },
                    body: JSON.stringify(newPackaging),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to save custom shipment packaging');
            }

            setNewPackaging({ name: '', length: '', width: '', height: '', maxWeight: '' });
            await fetchShipmentPackagingOptions();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsAddingPackaging(false);
        }
    };

    const shortenDescription = (orderContent) => {
        let description = orderContent
            .map((item) => {
                let sku = item.sku.replace(/^SC-/, ''); // Remove 'SC-' prefix
                return `${item.quantity}x${sku}`;
            })
            .join(',');

        if (description.length > 47) {
            description = description.slice(0, 47) + '!!!';
        }

        return description;
    };

    const convertToCSV = () => {
        const selectedOrdersData = [...orders.ebay, ...orders.squarespace].filter((order) =>
            selectedOrders.includes(order.orderId)
        );

        const csvRows = [];
        const headers = [
            'Send From Name',
            'Send From Address Line 1',
            'Send From Address Line 2',
            'Send From Address Line 3',
            'Send From Suburb',
            'Send From State',
            'Send From Postcode',
            'Deliver To Name',
            'Deliver To Business Name',
            'Deliver To Address Line 1',
            'Deliver To Address Line 2',
            'Deliver To Address Line 3',
            'Deliver To Suburb',
            'Deliver To State',
            'Deliver To Postcode',
            'Item Packaging Type',
            'Item Delivery Service',
            'Item weight',
            'Item length',
            'Item width',
            'Item height',
            'Deliver To Email Address',
            'Signature on Delivery',
            'Send Tracking Notifications',
            'Send From Business Name',
            'Send From Phone Number',
            'Send From Email Address',
            'Deliver To Phone Number',
            'Additional Label Information 1',
            'Item Description',
        ];
        csvRows.push(headers.join(','));

        const removeCommas = (str) => str.replace(/,/g, '');

        for (const order of selectedOrdersData) {
            const verifiedAddress = verifiedAddresses[order.orderId] || order.address;
            console.log('Address being used for CSV:', verifiedAddress);
            const customSize =
                shipmentPackagingOptions.find((option) => option.code === order.shipmentPackaging) || {};

            // Ensure address fields are not empty, within character limits, and free of commas
            const deliverToName = removeCommas(order.address.name).slice(0, 35);
            const deliverToAddressLine1 = removeCommas(verifiedAddress.street1 || '').slice(0, 40);
            const deliverToAddressLine2 = removeCommas(verifiedAddress.street2 || '').slice(0, 40);
            const deliverToAddressLine3 = removeCommas(verifiedAddress.street3 || '').slice(0, 40);
            const deliverToSuburb = removeCommas(verifiedAddress.suburb || '').slice(0, 30);
            const deliverToState = removeCommas(verifiedAddress.state || '');
            const deliverToPostcode = removeCommas(verifiedAddress.postalCode || '').slice(0, 4);

            // Format phone number
            const formatPhoneNumber = (phone) => {
                if (!phone) return '';
                const digits = phone.replace(/\D/g, '');
                return digits.startsWith('61') ? '0' + digits.slice(2) : digits.startsWith('0') ? digits : '0' + digits;
            };

            // Determine if the packaging is custom
            const isCustomPackaging = shipmentPackagingOptions.find(
                (option) => option.code === order.shipmentPackaging && option.type === 'CUSTOM'
            );

            // Use the selected delivery service from the state
            const deliveryService = deliveryServices[order.orderId] || 'PP';

            const row = [
                removeCommas(senderInfo.name).slice(0, 35),
                removeCommas(senderInfo.address1).slice(0, 40),
                '', // Send From Address Line 2
                '', // Send From Address Line 3
                removeCommas(senderInfo.suburb).slice(0, 30),
                removeCommas(senderInfo.state),
                removeCommas(senderInfo.postcode).slice(0, 4),
                deliverToName,
                '', // Deliver To Business Name
                deliverToAddressLine1,
                deliverToAddressLine2,
                deliverToAddressLine3,
                deliverToSuburb,
                deliverToState,
                deliverToPostcode,
                isCustomPackaging ? 'OWN_PACKAGING' : (order.shipmentPackaging || 'OWN_PACKAGING'),
                deliveryService, // Use the selected delivery service
                orderWeights[order.orderId] || '5',
                customSize.length || '',
                customSize.width || '',
                customSize.height || '',
                removeCommas(order.email || ''),
                'NO',
                'YES',
                removeCommas(senderInfo.businessName).slice(0, 40),
                formatPhoneNumber(senderInfo.phoneNumber).slice(0, 10),
                removeCommas(senderInfo.email).slice(0, 50),
                formatPhoneNumber(order.phone).slice(0, 20),
                removeCommas(shortenDescription(order.orderContent)).slice(0, 50),
                removeCommas(order.orderId).slice(0, 50),
            ];
            csvRows.push(row.join(','));
        }

        return csvRows.join('\n');
    };

    const saveAllShortDescriptionPackaging = async () => {
        const allOrders = [...orders.ebay, ...orders.squarespace];
        for (const order of allOrders) {
            if (order.shipmentPackaging) {
                const shortDesc = shortenDescription(order.orderContent);
                await saveShortDescriptionPackaging(shortDesc, order.shipmentPackaging);
            }
        }
    };

    const downloadCSV = async () => {
        setIsDownloadingCSV(true);
        try {
            // Save all short description packaging before generating CSV
            await saveAllShortDescriptionPackaging();

            const csv = convertToCSV();
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
                const fileName = `SILIHUB_ORDERS_${today}.csv`;
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading CSV:', error);
        } finally {
            setIsDownloadingCSV(false);
        }
    };

    const openManagePackagingModal = () => {
        setIsManagePackagingModalOpen(true);
        // If you need any additional functionality that was in the duplicate declaration,
        // you can add it here.
    };

    const closeManagePackagingModal = () => {
        setIsManagePackagingModalOpen(false);
        setEditingPackaging(null);
    };

    const handleEditPackaging = (packaging) => {
        setEditingPackaging(packaging);
    };

    const handleSaveEditedPackaging = async () => {
        try {
            // Ensure all values are defined and convert to appropriate types
            const packagingData = {
                code: editingPackaging.code,
                name: editingPackaging.name || '',
                length: editingPackaging.length ? Number(editingPackaging.length) : null,
                width: editingPackaging.width ? Number(editingPackaging.width) : null,
                height: editingPackaging.height ? Number(editingPackaging.height) : null,
                maxWeight: editingPackaging.max_weight ? Number(editingPackaging.max_weight) : null,
            };

            const response = await fetch(
                'https://silicreate-web-giver.silicreate.workers.dev/edit-shipment-packaging',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('authToken'),
                    },
                    body: JSON.stringify(packagingData),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to edit shipment packaging');
            }

            setEditingPackaging(null);
            fetchShipmentPackagingOptions();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeletePackaging = async (code) => {
        if (window.confirm('Are you sure you want to delete this custom packaging?')) {
            try {
                const response = await fetch(
                    'https://silicreate-web-giver.silicreate.workers.dev/delete-shipment-packaging',
                    {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: localStorage.getItem('authToken'),
                        },
                        body: JSON.stringify({ code }),
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to delete shipment packaging');
                }

                fetchShipmentPackagingOptions();
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const handleWeightChange = (orderId, value) => {
        // Allow only numbers and a single decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            setOrderWeights((prev) => ({
                ...prev,
                [orderId]: value,
            }));
        }
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1400px', // Increased max width for wider layout
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: '8px',
            padding: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    const editModalStyles = {
        content: {
            ...customModalStyles.content,
            maxWidth: '400px', // Even smaller for the edit modal
        },
        overlay: customModalStyles.overlay,
    };

    const saveShortDescriptionPackaging = async (shortDescription, packaging) => {
        try {
            const response = await fetch(
                'https://silicreate-web-giver.silicreate.workers.dev/short-description-packaging',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('authToken'),
                    },
                    body: JSON.stringify({ shortDescription, packaging }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to save short description packaging');
            }
        } catch (error) {
            console.error('Error saving short description packaging:', error);
        }
    };

    const handleAddressEdit = (order, platform) => {
        setSelectedAddress({
            ...order.address,
            street3: order.address.street3 || '', // Add this line
            orderId: order.orderId,
            platform
        });
        setIsAddressModalOpen(true);
    };

    const handleAddressSave = (updatedAddress) => {
        console.log('Updated address received in ShipOrders:', updatedAddress);
        setOrders((prev) => ({
            ...prev,
            [updatedAddress.platform]: prev[updatedAddress.platform].map((order) =>
                order.orderId === updatedAddress.orderId
                    ? { ...order, address: updatedAddress }
                    : order
            ),
        }));
        setVerifiedAddresses((prev) => ({
            ...prev,
            [updatedAddress.orderId]: {
                ...updatedAddress,
                userConfirmed: true,
            }
        }));
        setIsAddressModalOpen(false);

        // Check if the updated address resolves any issues
        const { suburb, state, postalCode } = updatedAddress;
        if (suburb && state && postalCode) {
            setOrdersWithIssues((prev) => {
                const newIssues = { ...prev };
                delete newIssues[updatedAddress.orderId];
                return newIssues;
            });
        }
    };

    const verifyAddress = async (address, orderId) => {
        if (address.street1.toLowerCase().includes('ebay') || address.street1 === '') {
            console.log(address);
            address.street1 = address.street2 || '';
            address.street2 = '';
        }
        const query = encodeURIComponent(
            `${address.street1} ${address.street2 || ''} ${address.suburb} ${address.state} ${
                address.postalCode
            }`.trim()
        );
        const url = `https://silicreate-web-giver.silicreate.workers.dev/verify-address?q=${query}`;

        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: localStorage.getItem('authToken'),
                },
            });
            if (!response.ok) {
                throw new Error('Failed to verify address');
            }
            const data = await response.json();
            if (data.length > 0) {
                const structuredData = data[0]; // Assuming the worker returns the structured data directly
                const verifiedAddress = {
                    street1: `${structuredData.structured.number.number} ${structuredData.structured.street.name} ${structuredData.structured.street.type.name}`,
                    street2: '',
                    suburb: structuredData.structured.locality.name,
                    state: structuredData.structured.state.abbreviation,
                    postalCode: structuredData.structured.postcode,
                    country: 'AU',
                };
                const isMatch = compareAddresses(address, verifiedAddress);
                setVerifiedAddresses((prev) => ({
                    ...prev,
                    [orderId]: { ...verifiedAddress, isMatch, sla: structuredData.sla },
                }));
                return verifiedAddress;
            } else {
                console.log('No verified address found for:', address);
                return null;
            }
        } catch (error) {
            console.error('Error verifying address:', error);
            return null;
        }
    };

    const compareAddresses = (original, verified) => {
        // Normalize addresses for comparison
        const normalizeAddress = (addr) => ({
            street: (addr.street1 || '').toLowerCase().replace(/\s+/g, ' ').trim(),
            suburb: (addr.suburb || '').toLowerCase().trim(),
            state: (addr.state || '').toLowerCase().trim(),
            postalCode: (addr.postalCode || '').trim(),
        });
    
        const normalizedOriginal = normalizeAddress(original);
        const normalizedVerified = normalizeAddress(verified);
    
        // Normalize street types (e.g., 'st' to 'street')
        const streetTypeMappings = {
            'st': 'street',
            'rd': 'road',
            'ave': 'avenue',
            'blvd': 'boulevard',
            'bvd': 'boulevard',
            'ct': 'court',
            'dr': 'drive',
            'ln': 'lane',
            'ter': 'terrace',
            'pl': 'place',
            'pkwy': 'parkway',
            'sq': 'square'
        };
    
        const normalizeStreet = (street) => {
            let normalizedStreet = street;
            for (const [abbr, full] of Object.entries(streetTypeMappings)) {
                const regex = new RegExp(`\\b${abbr}\\b`, 'gi');
                normalizedStreet = normalizedStreet.replace(regex, full);
            }
            return normalizedStreet;
        };
    
        normalizedOriginal.street = normalizeStreet(normalizedOriginal.street);
        normalizedVerified.street = normalizeStreet(normalizedVerified.street);
    
        return (
            normalizedOriginal.street === normalizedVerified.street &&
            normalizedOriginal.suburb === normalizedVerified.suburb &&
            normalizedOriginal.state === normalizedVerified.state &&
            normalizedOriginal.postalCode === normalizedVerified.postalCode
        );
    };


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allOrderIds = [...orders.ebay, ...orders.squarespace].map((order) => order.orderId);
            setSelectedOrders(allOrderIds);
        } else {
            setSelectedOrders([]);
        }
    };

    useEffect(() => {
        if (selectAll) {
            const allOrderIds = [...orders.ebay, ...orders.squarespace].map((order) => order.orderId);
            setSelectedOrders(allOrderIds);
        }
    }, [selectAll, orders]);

    const parseInvoiceData = (invoiceText) => {
        // Extract date, GST, and total amount as before
        const datePattern = /Issued date\s+([\d]{1,2}\s\w+\s\d{4})/;
        const dateMatch = invoiceText.match(datePattern);
        const issuedDate = dateMatch ? dateMatch[1] : null;

        const gstPattern = /Included GST\s+\$(\d+\.\d{2})/;
        const gstMatch = invoiceText.match(gstPattern);
        const gstAmount = gstMatch ? `$${gstMatch[1]}` : null;

        const totalAmountPattern = /Total Amount Payable\s+\$(\d+\.\d{2})/;
        const totalAmountMatch = invoiceText.match(totalAmountPattern);
        const totalAmount = totalAmountMatch ? `$${totalAmountMatch[1]}` : null;

        // Check if the invoice is from Australia Post
        const isAustraliaPost = invoiceText.includes('Australia Post');

        // Updated regex pattern to extract shipping details for both Squarespace and eBay orders
        const shippingDetailsPattern = /(Express Post|Parcel Post)\s+(\d+)\s+(\d+)\s+([\w\-]+(?:\s+[\w\-]+)*)\s+\$(\d+\.\d{2})/g;
        const matches = [];
        let match;

        while ((match = shippingDetailsPattern.exec(invoiceText)) !== null) {
            const [_, shippingService, trackingNumber1, trackingNumber2, orderId, postageCost] = match;
            const fullTrackingNumber = trackingNumber1 + trackingNumber2;

            // Remove all spaces from the order ID
            const cleanOrderId = orderId.replace(/\s+/g, '');

            // Determine the platform (eBay or Squarespace) based on the order ID format
            const platform = cleanOrderId.includes('-') ? 'ebay' : 'squarespace';

            // Determine the correct carrier name and service based on the platform
            let carrierName, service;
            if (platform === 'ebay') {
                carrierName = 'AustraliaPost';
                service = shippingService === 'Express Post' ? 'AU_ExpressDelivery' : 'AU_StandardDelivery';
            } else {
                carrierName = 'Australia Post';
                service = shippingService;
            }

            matches.push({
                'Order ID': cleanOrderId,
                'Tracking Number': fullTrackingNumber,
                'Shipping Service': shippingService,
                'Postage Cost': `$${postageCost}`,
                'Platform': platform,
                'Carrier Name': carrierName,
            });
        }

        console.log('Extracted shipments:', matches);

        return {
            IssuedDate: issuedDate,
            GST: gstAmount,
            TotalAmount: totalAmount,
            Shipments: matches,
            IsAustraliaPost: isAustraliaPost,
        };
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            if (isSubmitted) {
                return;
            }

            setIsProcessingPdf(true);
            const file = acceptedFiles[0];
            try {
                const raw_text = await pdfToText(file);
                console.log('Extracted raw text:', raw_text);
                const invoiceData = parseInvoiceData(raw_text);

                console.log('Parsed invoice data:', invoiceData);

                // Update state with the parsed data and save the PDF file
                setPdfData(invoiceData);
                setPdfFile(file);
            } catch (error) {
                console.error('Error processing PDF:', error);
                setError('Failed to process PDF. Please try again.');
            } finally {
                setIsProcessingPdf(false);
            }
        },
        [isSubmitted]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: isSubmitted,
    });

    const handleSendExpense = async (expenseData) => {
        let dataToSend = {
            dateOfTransaction: new Date().toISOString(),
            expenseType: 'Postage',
            taxRate: 'GST on Expenses',
            amount: expenseData.TotalAmount,
            currency: 'AUD',
            paymentMethod: 'Credit Card - ANZ',
            receiptBase64: '',
            fileType: 'pdf',
            notes: 'SiliHubAPI-AusPost',
            useCase: 'Order Fulfillment',
        };

        if (pdfFile) {
            // Convert PDF to base64
            const base64Pdf = await convertToBase64(pdfFile);
            dataToSend = {
                ...dataToSend,
                receiptBase64: base64Pdf,
            };
        }

        try {
            const response = await fetch(
                'https://silicreate-web-giver.silicreate.workers.dev/send-expense-to-sheets',
                {
                    method: 'POST',
                    headers: {
                        Authorization: localStorage.getItem('authToken'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataToSend),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to send expense data');
            }

            const result = await response.json();
            console.log(result.message);
            // Handle success (e.g., show a success message to the user)
        } catch (error) {
            console.error('Error sending expense data:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setSubmissionStatus({});

        const selectedOrderFulfillments = pdfData.Shipments.filter((shipment) =>
            selectedShipments[shipment['Order ID']]
        ).map((shipment) => ({
            orderId: shipment['Order ID'],
            trackingNumber: shipment['Tracking Number'],
            platform: shipment['Platform'],
            carrierName: shipment['Carrier Name'],
            service: shipment['Service'],
            trackingUrl: `https://auspost.com.au/mypost/track/#/details/${shipment['Tracking Number']}`,
        }));

        try {
            // Send expense data if selected
            if (selectedShipments['expense']) {
                await handleSendExpense(pdfData);
                setExpenseSubmitted(true);
            }

            // Send order fulfillments individually
            const promises = selectedOrderFulfillments.map(async (fulfillment) => {
                try {
                    await handleAllSubmit(fulfillment);
                    return { orderId: fulfillment.orderId, success: true };
                } catch (error) {
                    return { orderId: fulfillment.orderId, success: false, error: error.message };
                }
            });

            await Promise.all(promises);

            setIsSubmitted(true);
        } catch (error) {
            console.error('Submission error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleAllSubmit = async (orderFulfillment) => {
        const response = await fetch(
            'https://silicreate-web-giver.silicreate.workers.dev/fulfill-order',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('authToken'),
                },
                body: JSON.stringify({
                    orderId: orderFulfillment.orderId,
                    trackingNumber: orderFulfillment.trackingNumber,
                    platform: orderFulfillment.platform,
                    carrierName: orderFulfillment.carrierName,
                    service: orderFulfillment.service,
                    trackingUrl: orderFulfillment.trackingUrl,
                }),
            }
        );

        if (!response.ok) {
            setSubmissionStatus((prev) => ({
                ...prev,
                [orderFulfillment.orderId]: { success: false, error: 'Failed to fulfill order' },
            }));
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fulfill order');
        }
        setSubmissionStatus((prev) => ({
            ...prev,
            [orderFulfillment.orderId]: { success: true },
        }));
        return await response.json();
    };

    const handleShipmentSelect = (orderId) => {
        if (!isSubmitted) {
            setSelectedShipments((prev) => ({
                ...prev,
                [orderId]: !prev[orderId],
            }));
        }
    };

    const fetchFormattedAddress = async (pid) => {
        try {
            const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/formatted-address/${pid}`, {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch formatted address');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching formatted address:', error);
            return null;
        }
    };

    const handleDeliveryServiceChange = (orderId, value) => {
        setDeliveryServices(prev => ({...prev, [orderId]: value}));
    };

    if (isLoadingOrders) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center text-red-500 mt-10">
                <h2 className="text-2xl font-bold">Error</h2>
                <p>{error}</p>
            </div>
        );
    }

    return (
        <Layout>
            <div className="min-h-screen ">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <h1 className="text-3xl font-semibold text-gray-900 mb-6">Ship Orders</h1>
                    {Object.keys(ordersWithIssues).length > 0 && (
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
                            <p className="font-bold">Attention needed</p>
                            <p>Some orders have incomplete address information. Please fix them before proceeding.</p>
                        </div>
                    )}
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-3xl font-bold">📦2️⃣🚀</h1>
                        <button
                            onClick={openManagePackagingModal}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            Manage Packaging
                        </button>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white table-auto">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 text-left">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th className="px-2 py-2 text-left">Platform</th>
                                    <th className="px-2 py-2 text-left">Order ID</th>
                                    <th className="px-2 py-2 text-left">Customer</th>
                                    <th className="px-2 py-2 text-left">Items</th>
                                    <th className="px-2 py-2 text-left">Total</th>
                                    <th className="px-2 py-2 text-left">Shipping Speed</th>
                                    <th className="px-2 py-2 text-left">Address</th>
                                    <th className="px-2 py-2 text-left">Shipment Packaging</th>
                                    <th className="px-2 py-2 text-left">Weight (kg)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {['ebay', 'squarespace'].map((platform) =>
                                    orders[platform].map((order) => (
                                        <tr key={order.orderId} className="border-t">
                                            <td className="px-2 py-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedOrders.includes(order.orderId)}
                                                    onChange={() => handleOrderSelect(order.orderId)}
                                                />
                                            </td>
                                            <td className="px-2 py-2">{platform}</td>
                                            <td className="px-2 py-2">{order.orderId}</td>
                                            <td className="px-2 py-2">{order.address.name}</td>
                                            <td className="px-2 py-2">{shortenDescription(order.orderContent)}</td>
                                            <td className="px-2 py-2">${order.orderTotal.toFixed(2)}</td>
                                            <td className="px-2 py-2">
                                                <select
                                                    value={deliveryServices[order.orderId] || 'PP'}
                                                    onChange={(e) => handleDeliveryServiceChange(order.orderId, e.target.value)}
                                                    className="border rounded px-2 py-1"
                                                >
                                                    <option value="PP">Parcel Post</option>
                                                    <option value="EXP">Express Post</option>
                                                </select>
                                            </td>
                                            <td className="px-4 py-2">
                                                <div className="text-xs cursor-pointer" onClick={() => handleAddressEdit(order, platform)}>
                                                    <p className="font-semibold">Original Address:</p>
                                                    <p>{order.address.street1}</p>
                                                    {order.address.street2 && <p>{order.address.street2}</p>}
                                                    {order.address.street3 && <p>{order.address.street3}</p>}
                                                    <p>{order.address.suburb}, {order.address.state} {order.address.postalCode}</p>
                                                    {ordersWithIssues[order.orderId] && (
                                                        <p className="text-red-500 font-semibold">
                                                            ❌ Missing: 
                                                            {ordersWithIssues[order.orderId].missingSuburb && ' Suburb'}
                                                            {ordersWithIssues[order.orderId].missingState && ' State'}
                                                            {ordersWithIssues[order.orderId].missingPostalCode && ' Postal Code'}
                                                        </p>
                                                    )}
                                                    {verifiedAddresses[order.orderId] && (
                                                        <>
                                                            <p className="font-semibold mt-2">Verified Address:</p>
                                                            <p>{verifiedAddresses[order.orderId].street1}</p>
                                                            {verifiedAddresses[order.orderId].street2 && <p>{verifiedAddresses[order.orderId].street2}</p>}
                                                            <p>{verifiedAddresses[order.orderId].suburb}, {verifiedAddresses[order.orderId].state} {verifiedAddresses[order.orderId].postalCode}</p>
                                                            {verifiedAddresses[order.orderId].userConfirmed ? (
                                                                <span className="text-green-500">✓ User Confirmed</span>
                                                            ) : verifiedAddresses[order.orderId].isMatch ? (
                                                                <span className="text-green-500">✓ Addresses Match</span>
                                                            ) : (
                                                                <span className="text-red-500">✗ Addresses Do Not Match</span>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </td>

                                            <td className="px-2 py-2 flex items-center">
                                                <select
                                                    value={
                                                        order.shipmentPackaging || shipmentPackagingOptions[0].label
                                                    }
                                                    onChange={(e) =>
                                                        handleShipmentPackagingChange(order.orderId, e.target.value)
                                                    }
                                                    className="border rounded px-2 py-1"
                                                >
                                                    {shipmentPackagingOptions.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                            disabled={option.value === ''}
                                                        >
                                                            {option.value === ''
                                                                ? 'Choose An Option'
                                                                : option.label}
                                                        </option>
                                                    ))}
                                                    <option value="ADD_NEW_SIZE">Add New Size</option>
                                                </select>
                                                {order.shipmentPackaging ? (
                                                    <span className="ml-2 text-green-500">✅</span>
                                                ) : (
                                                    <span className="ml-2 text-red-500">❌</span>
                                                )}
                                            </td>
                                            <td className="px-2 py-2">
                                                <input
                                                    type="text"
                                                    value={orderWeights[order.orderId] || '5'}
                                                    onChange={(e) => handleWeightChange(order.orderId, e.target.value)}
                                                    className="border rounded w-20 px-2 py-1"
                                                />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <button
                        onClick={downloadCSV}
                        className={`mt-4 px-4 py-2 bg-purple-300 text-purple-800 rounded hover:bg-purple-400 ${
                            isDownloadingCSV ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={selectedOrders.length === 0 || isDownloadingCSV}
                    >
                        {isDownloadingCSV ? (
                            <span className="flex items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Downloading...
                            </span>
                        ) : (
                            'Download CSV for Selected Orders'
                        )}
                    </button>

                    {/* PDF drop zone */}
                    <div className="mt-8">
                        <h2 className="text-xl font-bold mb-4">Upload Tax Invoice PDF</h2>
                        <div
                            {...getRootProps()}
                            className={`p-10 border-2 border-dashed rounded-lg text-center ${
                                isSubmitted
                                    ? 'bg-gray-100 cursor-not-allowed'
                                    : isDragActive
                                    ? 'border-blue-500 bg-blue-50'
                                    : 'border-gray-300 cursor-pointer'
                            }`}
                        >
                            <input {...getInputProps()} />
                            {isSubmitted ? (
                                <p>PDF already submitted. No new uploads allowed.</p>
                            ) : isDragActive ? (
                                <p>Drop the PDF file here ...</p>
                            ) : (
                                <p>Drag 'n' drop a PDF file here, or click to select one</p>
                            )}
                        </div>
                        {isProcessingPdf && <p className="mt-2 text-blue-500">Processing PDF...</p>}
                        {pdfData && (
                            <div className="mt-8">
                                <h2 className="text-2xl font-bold mb-4">Order Fulfillments</h2>
                                <div className="grid grid-cols-3 gap-4 mb-8">
                                    {pdfData.Shipments.map((shipment, index) => (
                                        <div
                                            key={index}
                                            className={`relative p-4 rounded-lg shadow-md transition-all duration-300 ${
                                                isSubmitting && selectedShipments[shipment['Order ID']]
                                                    ? 'bg-blue-100'
                                                    : submissionStatus[shipment['Order ID']]?.success
                                                    ? 'bg-green-100'
                                                    : submissionStatus[shipment['Order ID']]?.success === false
                                                    ? 'bg-red-100'
                                                    : 'bg-white'
                                            }`}
                                        >
                                            <div className="absolute top-2 right-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedShipments[shipment['Order ID']]}
                                                    onChange={() => handleShipmentSelect(shipment['Order ID'])}
                                                    disabled={isSubmitted}
                                                    className={`form-checkbox h-5 w-5 ${
                                                        isSubmitted
                                                            ? 'text-gray-400 cursor-not-allowed'
                                                            : 'text-blue-600'
                                                    }`}
                                                />
                                            </div>
                                            <h3 className="font-semibold text-lg">
                                                Order ID: {shipment['Order ID']}
                                            </h3>
                                            <p className="text-gray-600">
                                                Tracking: {shipment['Tracking Number']}
                                            </p>
                                            {submissionStatus[shipment['Order ID']] && (
                                                <div className="mt-2">
                                                    {submissionStatus[shipment['Order ID']].success ? (
                                                        <span className="text-green-600 font-semibold">
                                                            ✓ Submitted
                                                        </span>
                                                    ) : (
                                                        <span className="text-red-600 font-semibold">
                                                            ✗ Error: {submissionStatus[shipment['Order ID']].error}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            {isSubmitting &&
                                                selectedShipments[shipment['Order ID']] &&
                                                !submissionStatus[shipment['Order ID']] && (
                                                    <div className="absolute inset-0 flex items-center justify-center bg-blue-200 bg-opacity-75 rounded-lg">
                                                        <svg
                                                            className="animate-spin h-8 w-8 text-blue-600"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>

                                <div
                                    className={`relative bg-white p-6 rounded-lg shadow-md mb-8 transition-all duration-300 ${
                                        isSubmitting && selectedShipments['expense']
                                            ? 'bg-blue-100'
                                            : expenseSubmitted
                                            ? 'bg-green-100'
                                            : 'bg-white'
                                    }`}
                                >
                                    <div className="absolute top-2 right-2">
                                        <input
                                            type="checkbox"
                                            checked={selectedShipments['expense']}
                                            onChange={() => handleShipmentSelect('expense')}
                                            disabled={isSubmitted}
                                            className={`form-checkbox h-5 w-5 ${
                                                isSubmitted ? 'text-gray-400 cursor-not-allowed' : 'text-blue-600'
                                            }`}
                                        />
                                    </div>
                                    <h3 className="text-xl font-semibold mb-4">Expense Data</h3>
                                    <p>
                                        <span className="font-medium">Issued Date:</span> {pdfData.IssuedDate}
                                    </p>
                                    <p>
                                        <span className="font-medium">GST:</span> {pdfData.GST}
                                    </p>
                                    <p>
                                        <span className="font-medium">Total Amount:</span> {pdfData.TotalAmount}
                                    </p>
                                    {expenseSubmitted && (
                                        <p className="text-green-600 font-semibold mt-2">
                                            ✓ Expense submitted to Google Sheets
                                        </p>
                                    )}
                                    {isSubmitting && selectedShipments['expense'] && !expenseSubmitted && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-blue-200 bg-opacity-75 rounded-lg">
                                            <svg
                                                className="animate-spin h-8 w-8 text-blue-600"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                        </div>
                                    )}
                                </div>

                                <button
                                    onClick={handleSubmit}
                                    disabled={isSubmitting || isSubmitted}
                                    className={`w-full py-3 rounded-lg font-semibold text-white transition-all duration-300 ${
                                        isSubmitting || isSubmitted
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-blue-500 hover:bg-blue-600'
                                    }`}
                                >
                                    {isSubmitted ? 'SUBMITTED' : 'Submit Selected'}
                                </button>

                                {submissionStatus.error && (
                                    <p className="mt-4 text-red-600 font-semibold">{submissionStatus.error}</p>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Modals */}
                    <ManagePackagingModal
                        isOpen={isManagePackagingModalOpen}
                        onClose={closeManagePackagingModal}
                        shipmentPackagingOptions={shipmentPackagingOptions}
                        isLoadingPackagingOptions={isLoadingPackagingOptions}
                        handleEditPackaging={handleEditPackaging}
                        handleDeletePackaging={handleDeletePackaging}
                        newPackaging={newPackaging}
                        handleNewPackagingChange={handleNewPackagingChange}
                        handleSaveNewPackaging={handleSaveNewPackaging}
                        isAddingPackaging={isAddingPackaging}
                    />

                    {editingPackaging && (
                        <EditPackagingModal
                            isOpen={!!editingPackaging}
                            onClose={() => setEditingPackaging(null)}
                            editingPackaging={editingPackaging}
                            setEditingPackaging={setEditingPackaging}
                            handleSaveEditedPackaging={handleSaveEditedPackaging}
                        />
                    )}

                    <AddressModal
                        isOpen={isAddressModalOpen}
                        onClose={() => setIsAddressModalOpen(false)}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        onSave={handleAddressSave}
                    />

                </div>
            </div>
        </Layout>
    );
};

export default ShipOrders;