import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const AddressModal = ({
    isOpen,
    onClose,
    selectedAddress,
    setSelectedAddress,
    onSave,
}) => {
    const [isSearching, setIsSearching] = useState(false);
    const [addressSearchQuery, setAddressSearchQuery] = useState('');
    const [addressSearchResults, setAddressSearchResults] = useState([]);

    useEffect(() => {
        if (selectedAddress && selectedAddress.street1) {
            const simplifiedAddress = selectedAddress.street1.split(' ').slice(0, -1).join(' ');
            setAddressSearchQuery(simplifiedAddress);
        }
        // Clear search results when opening a new modal
        setAddressSearchResults([]);
    }, [selectedAddress]);

    const handleSearch = async () => {
        setIsSearching(true);
        try {
            const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/search-address?q=${encodeURIComponent(addressSearchQuery)}`, {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to search addresses: ${response.status}`);
            }
            const data = await response.json();
            if (data.error) {
                throw new Error(data.error);
            }
            setAddressSearchResults(data);
        } catch (error) {
            console.error('Error searching addresses:', error);
            setAddressSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    const handleAddressSelect = async (result) => {
        if (result.pid) {
            try {
                const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/formatted-address/${result.pid}`, {
                    headers: {
                        'Authorization': localStorage.getItem('authToken')
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch formatted address');
                }
                const formattedAddress = await response.json();
                console.log('Formatted address from API:', formattedAddress);
                const newAddress = {
                    ...selectedAddress,
                    street1: (formattedAddress.street1 || '').replace(/,/g, ''),
                    street2: (formattedAddress.street2 || '').replace(/,/g, ''),
                    street3: (formattedAddress.street3 || '').replace(/,/g, ''),
                    suburb: formattedAddress.suburb || '',
                    state: formattedAddress.state || '',
                    postalCode: formattedAddress.postalCode || '',
                    country: 'AU',
                };
                console.log('New address after formatting:', newAddress);
                setSelectedAddress(newAddress);
                setAddressSearchQuery(newAddress.street1);
            } catch (error) {
                console.error('Error fetching formatted address:', error);
            }
        }
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1200px',
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: '8px',
            padding: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customModalStyles}
            contentLabel="Address Details"
        >
            <div className="relative">
                <h2 className="text-2xl font-bold mb-4">Edit Address</h2>
                <button
                    onClick={onClose}
                    className="absolute top-0 right-0 text-gray-600 hover:text-gray-800"
                >
                    <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                {selectedAddress && (
                    <div className="grid grid-cols-1 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Street Address 1</label>
                            <input
                                type="text"
                                value={selectedAddress.street1}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, street1: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter street address"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Street Address 2</label>
                            <input
                                type="text"
                                value={selectedAddress.street2}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, street2: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter additional address info (optional)"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Street Address 3</label>
                            <input
                                type="text"
                                value={selectedAddress.street3}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, street3: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter additional address info (optional)"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Suburb</label>
                            <input
                                type="text"
                                value={selectedAddress.suburb}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, suburb: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter suburb"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">State</label>
                            <input
                                type="text"
                                value={selectedAddress.state}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, state: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter state"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Postal Code</label>
                            <input
                                type="text"
                                value={selectedAddress.postalCode}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, postalCode: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter postal code"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Country</label>
                            <input
                                type="text"
                                value={selectedAddress.country}
                                onChange={(e) => setSelectedAddress({ ...selectedAddress, country: e.target.value })}
                                className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3"
                                placeholder="Enter country"
                            />
                        </div>
                        
                        {/* Address Search */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Search Address</label>
                            <div className="flex">
                                <input
                                    type="text"
                                    value={addressSearchQuery}
                                    onChange={(e) => setAddressSearchQuery(e.target.value)}
                                    className="mt-1 block w-full border rounded-l-md shadow-sm py-2 px-3"
                                    placeholder="Enter address to search"
                                />
                                <button
                                    onClick={handleSearch}
                                    className="mt-1 px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 flex items-center"
                                    disabled={isSearching}
                                >
                                    {isSearching ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Searching...
                                        </>
                                    ) : 'Search'}
                                </button>
                            </div>
                        </div>
                        {/* Search Results */}
                        {addressSearchResults.length > 0 && (
                            <div>
                                <h3 className="text-lg font-semibold mt-4">Search Results</h3>
                                <ul className="max-h-40 overflow-y-auto border p-2">
                                    {addressSearchResults.map((result, index) => (
                                        <li
                                            key={index}
                                            className="p-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleAddressSelect(result)}
                                        >
                                            {result.sla}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => {
                                    console.log('Address being saved:', selectedAddress);
                                    onSave(selectedAddress);
                                    onClose();
                                }}
                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                            >
                                Save
                            </button>
                            <button
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default AddressModal;