import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import LoadingSpinner from '../components/LoadingSpinner';

const WarehouseStock = () => {
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingQuantity, setEditingQuantity] = useState({});
  const [savingQuantity, setSavingQuantity] = useState({});
  const [hiddenGroups, setHiddenGroups] = useState(() => {
    const saved = localStorage.getItem('hiddenGroups');
    return saved ? JSON.parse(saved) : [];
  });
  const [hiddenSkus, setHiddenSkus] = useState(() => {
    const saved = localStorage.getItem('hiddenSkus');
    return saved ? JSON.parse(saved) : [];
  });
  const [isUnhideModalOpen, setIsUnhideModalOpen] = useState(false);
  const [showPackaging, setShowPackaging] = useState(false);
  const [showForFBA, setShowForFBA] = useState(false);
  const [packagingStock, setPackagingStock] = useState([]);

  useEffect(() => {
    fetchStock();
    if (showPackaging) {
      fetchPackagingStock();
    }
  }, [showPackaging]);

  useEffect(() => {
    localStorage.setItem('hiddenGroups', JSON.stringify(hiddenGroups));
  }, [hiddenGroups]);

  useEffect(() => {
    localStorage.setItem('hiddenSkus', JSON.stringify(hiddenSkus));
  }, [hiddenSkus]);

  const fetchStock = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/warehouse-stock', {
        headers: {
          'Authorization': localStorage.getItem('authToken'),
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch warehouse stock');
      }
      const data = await response.json();
      setStock(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchPackagingStock = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/packaging-stock', {
        headers: {
          'Authorization': localStorage.getItem('authToken'),
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch packaging stock');
      }
      const data = await response.json();
      setPackagingStock(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const updateQuantity = async (sku, currentQuantity, newQuantity) => {
    setSavingQuantity(prev => ({ ...prev, [sku]: true }));
    try {
      const quantityDifference = newQuantity - currentQuantity;
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/update-stock-quantity', {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('authToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sku, quantity: quantityDifference }),
      });

      if (!response.ok) {
        throw new Error('Failed to update stock quantity');
      }

      // Refresh both product and packaging stock data
      await fetchStock();
      if (showPackaging) {
        await fetchPackagingStock();
      }

      setEditingQuantity(prev => {
        const newState = { ...prev };
        delete newState[sku];
        return newState;
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setSavingQuantity(prev => {
        const newState = { ...prev };
        delete newState[sku];
        return newState;
      });
    }
  };

  const handleQuantityChange = (sku, value) => {
    setEditingQuantity({ ...editingQuantity, [sku]: value });
  };

  const handleQuantitySubmit = (sku, currentQuantity) => {
    const newQuantity = parseInt(editingQuantity[sku], 10);
    if (!isNaN(newQuantity)) {
      updateQuantity(sku, currentQuantity, newQuantity);
    }
  };

  const hideGroup = (groupKey) => {
    setHiddenGroups([...hiddenGroups, groupKey]);
  };

  const hideSku = (sku) => {
    setHiddenSkus([...hiddenSkus, sku]);
  };

  const unhideGroup = (groupKey) => {
    setHiddenGroups(hiddenGroups.filter(g => g !== groupKey));
  };

  const unhideSku = (sku) => {
    setHiddenSkus(hiddenSkus.filter(s => s !== sku));
  };

  const openUnhideModal = () => {
    setIsUnhideModalOpen(true);
  };

  const closeUnhideModal = () => {
    setIsUnhideModalOpen(false);
  };

  const inferSize = (sku) => {
    const sizeMatch = sku.match(/-(\d+(?:\.\d+)?)$/);
    if (sizeMatch) {
      const size = parseFloat(sizeMatch[1]);
      if (size === 0.5) return '500g';
      if (size === 1) return '1kg';
      if (size === 2) return '2kg';
      if (size === 4) return '4kg';
      if (size === 8) return '8kg';
      if (size === 10) return '10kg';
      if (size === 20) return '20kg';
      return `${size}kg`;
    }
    return '';
  };

  const renderQuantityCell = (sku, quantity) => (
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
      {editingQuantity.hasOwnProperty(sku) ? (
        <div className="flex items-center justify-end">
          <input
            type="number"
            value={editingQuantity[sku]}
            onChange={(e) => handleQuantityChange(sku, e.target.value)}
            className="w-20 px-2 py-1 border rounded"
          />
          <button
            onClick={() => handleQuantitySubmit(sku, quantity)}
            disabled={savingQuantity[sku]}
            className={`ml-2 px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 flex items-center ${savingQuantity[sku] ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {savingQuantity[sku] ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </>
            ) : 'Save'}
          </button>
          <button
            onClick={() => {
              const newEditingQuantity = { ...editingQuantity };
              delete newEditingQuantity[sku];
              setEditingQuantity(newEditingQuantity);
            }}
            className="ml-2 px-2 py-1 text-red-500 hover:text-red-700"
            title="Cancel edit"
          >
            ✕
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-end">
          <span>{quantity || 0}</span>
          <button
            onClick={() => setEditingQuantity({ ...editingQuantity, [sku]: quantity || 0 })}
            className="ml-2 px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700"
          >
            Edit
          </button>
        </div>
      )}
    </td>
  );

  const renderPackagingStock = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {packagingStock.map((item, index) => (
        <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg relative">
          <div className="px-4 py-5 sm:px-6">
            <button
              onClick={() => hideGroup(item.sku_root)}
              className="absolute top-2 right-2 px-2 py-1 text-xs bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
            >
              Hide
            </button>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {item.name || 'N/A'}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {item.description || 'No description'}
            </p>
          </div>
          <div className="border-t border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {item.all_skus.map((sku, skuIndex) => (
                  <tr key={skuIndex} className="group">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative">
                      {sku}
                      <button
                        onClick={() => hideSku(sku)}
                        className="absolute inset-y-0 right-0 px-2 py-1 text-xs text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      >
                        Hide
                      </button>
                    </td>
                    {renderQuantityCell(sku, item.quantities[sku] || 0)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );

  const UnhideModal = () => (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Hidden Items
            </h3>
            <div className="mt-2">
              <h4 className="text-md font-medium text-gray-700">Hidden Groups</h4>
              <ul className="mt-2 space-y-2">
                {hiddenGroups.map(group => (
                  <li key={group} className="flex justify-between items-center">
                    <span>{group}</span>
                    <button
                      onClick={() => unhideGroup(group)}
                      className="px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 text-sm"
                    >
                      Unhide
                    </button>
                  </li>
                ))}
              </ul>
              <h4 className="text-md font-medium text-gray-700 mt-4">Hidden SKUs</h4>
              <ul className="mt-2 space-y-2">
                {hiddenSkus.map(sku => (
                  <li key={sku} className="flex justify-between items-center">
                    <span>{sku}</span>
                    <button
                      onClick={() => unhideSku(sku)}
                      className="px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 text-sm"
                    >
                      Unhide
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={closeUnhideModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const filteredStock = stock.filter(item => {
    const isPackaging = item.group_key.startsWith('SC-PACKAGING-');
    return showPackaging ? isPackaging : !isPackaging;
  });

  const groupedStock = filteredStock.filter(item => item.is_product === 1 && !hiddenGroups.includes(item.group_key));
  const ungroupedStock = filteredStock.filter(item => item.is_product === 0 && !hiddenGroups.includes(item.group_key));

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) return <Layout><div className="text-center mt-8 text-red-500">Error: {error}</div></Layout>;

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <h1 className="text-4xl font-bold mr-4">
              <span className="text-red-500">🏢</span>
              <span className="text-orange-500">📝</span>
              <span className="text-yellow-500"> </span>
              <span className="text-green-500">-</span>
              <span className="text-blue-500"> </span>
              <span className="text-indigo-500">W</span>
              <span className="text-purple-500">a</span>
              <span className="text-red-500">r</span>
              <span className="text-orange-500">e</span>
              <span className="text-yellow-500">h</span>
              <span className="text-green-500">o</span>
              <span className="text-blue-500">u</span>
              <span className="text-indigo-500">s</span>
              <span className="text-purple-500">e</span>
              <span className="text-red-500"> </span>
              <span className="text-orange-500">S</span>
              <span className="text-yellow-500">t</span>
              <span className="text-green-500">o</span>
              <span className="text-blue-500">c</span>
              <span className="text-indigo-500">k</span>
            </h1>
            <div className="flex items-center">
              <div className="inline-flex rounded-lg border border-gray-300 shadow-sm overflow-hidden">
                <button
                  className={`px-4 py-2 text-sm font-medium ${!showPackaging ? 'bg-purple-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                  onClick={() => {
                    setShowPackaging(false);
                    setShowForFBA(false);
                  }}
                >
                  Products
                </button>
                <button
                  className={`px-4 py-2 text-sm font-medium ${showPackaging && !showForFBA ? 'bg-purple-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                  onClick={() => {
                    setShowPackaging(true);
                    setShowForFBA(false);
                  }}
                >
                  Packaging
                </button>
                <button
                  className={`px-4 py-2 text-sm font-medium ${showPackaging && showForFBA ? 'bg-purple-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                  onClick={() => {
                    setShowPackaging(false);
                    setShowForFBA(true);
                  }}
                >
                  ForFBA
                </button>
               
              </div>
            </div>
            </div>
          {(hiddenGroups.length > 0 || hiddenSkus.length > 0) && (
            <button
              onClick={openUnhideModal}
              className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
            >
              Unhide Items ({hiddenGroups.length + hiddenSkus.length})
            </button>
          )}
        </div>
        {showPackaging ? renderPackagingStock() : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {groupedStock.map((item, index) => (
                <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg relative">
                  <div className="px-4 py-5 sm:px-6">
                    <button
                      onClick={() => hideGroup(item.group_key)}
                      className="absolute top-2 right-2 px-2 py-1 text-xs bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    >
                      Hide
                    </button>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {item.product_name || 'N/A'}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Total Quantity: {item.total_quantity || 0}
                    </p>
                  </div>
                  <div className="border-t border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                          <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {JSON.parse(item.sku_details || '[]')
                          .filter(detail => !hiddenSkus.includes(detail.sku))
                          .map((detail, detailIndex) => (
                            <tr key={detailIndex} className="group">
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-black relative">
                                {detail.sku}
                                <button
                                  onClick={() => hideSku(detail.sku)}
                                  className="absolute inset-y-0 right-0 px-2 py-1 text-xs text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                >
                                  Hide
                                </button>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{inferSize(detail.sku)}</td>
                              {renderQuantityCell(detail.sku, detail.quantity)}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>

            {ungroupedStock.length > 0 && (
              <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8 relative">
                <div className="px-4 py-5 sm:px-6">
                  <button
                    onClick={() => hideGroup('ungrouped')}
                    className="absolute top-2 right-2 px-2 py-1 text-xs bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                  >
                    Hide
                  </button>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Ungrouped SKUs</h3>
                </div>
                <div className="border-t border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Size</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {ungroupedStock
                        .filter(item => !hiddenSkus.includes(item.group_key))
                        .map((item, index) => (
                          <tr key={index} className="group">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative">
                              {item.group_key}
                              <button
                                onClick={() => hideSku(item.group_key)}
                                className="absolute inset-y-0 right-0 px-2 py-1 text-xs text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              >
                                Hide
                              </button>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{inferSize(item.group_key)}</td>
                            {renderQuantityCell(item.group_key, item.total_quantity)}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isUnhideModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Hidden Items
                </h3>
                <div className="mt-2">
                  <h4 className="text-md font-medium text-gray-700">Hidden Groups</h4>
                  <ul className="mt-2 space-y-2">
                    {hiddenGroups.map(group => (
                      <li key={group} className="flex justify-between items-center">
                        <span>{group}</span>
                        <button
                          onClick={() => unhideGroup(group)}
                          className="px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 text-sm"
                        >
                          Unhide
                        </button>
                      </li>
                    ))}
                  </ul>
                  <h4 className="text-md font-medium text-gray-700 mt-4">Hidden SKUs</h4>
                  <ul className="mt-2 space-y-2">
                    {hiddenSkus.map(sku => (
                      <li key={sku} className="flex justify-between items-center">
                        <span>{sku}</span>
                        <button
                          onClick={() => unhideSku(sku)}
                          className="px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 text-sm"
                        >
                          Unhide
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeUnhideModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default WarehouseStock;
