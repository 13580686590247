import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';  // Your main application component
import Login from './pages/Login';  // Your login component
import Header from './components/Header';  // Import the Header component
import ShipOrders from './pages/ShipOrders';
import OrderHistory from './pages/OrderHistory';
import Settings from './pages/Settings';
import BottlingRun from './pages/BottlingRun';
import WarehouseStock from './pages/WarehouseStock'; // Import the new component
import SellRate from './pages/SellRate';
import Invoicer from './pages/Invoicer';
import DirectOrders from './pages/DirectOrders';

const App = () => {
  // Function to check if auth token exists
  const isAuthenticated = () => {
    console.log('Checking authentication');
    console.log(localStorage.getItem('authToken'));
    const token = localStorage.getItem('authToken');
    return token !== null;
  };

  // Updated ProtectedRoute component
  const ProtectedRoute = ({ children }) => {
    return isAuthenticated() ? (
      <>
        <Header />
        {children}
      </>
    ) : (
      <Navigate to="/login" />
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/ship-orders"
          element={
            <ProtectedRoute>
              <ShipOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/direct-orders"
          element={
            <ProtectedRoute>
              <DirectOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/order-history"
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/invoicer"
          element={
            <ProtectedRoute>
              <Invoicer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/planning/bottling-run"
          element={
            <ProtectedRoute>
              <BottlingRun />
            </ProtectedRoute>
          }
        />
        <Route
          path="/planning/warehouse-stock"
          element={
            <ProtectedRoute>
              <WarehouseStock />
            </ProtectedRoute>
          }
        />
        <Route
          path="/planning/sell-rate"
          element={
            <ProtectedRoute>
              <SellRate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
