import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ThreeDots } from 'react-loader-spinner';

Modal.setAppElement('#root');

const PackagingSettings = () => {
  const [packagingOptions, setPackagingOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPackaging, setEditingPackaging] = useState(null);
  const [newPackaging, setNewPackaging] = useState({
    sku_root: 'SC-PACKAGING-',
    all_skus: [],
    name: '',
    description: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPackagingOptions();
  }, []);

  const fetchPackagingOptions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/get-packaging-options', {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch packaging options');
      }
      const data = await response.json();
      // Ensure all_skus is always an array
      const formattedData = data.map(item => ({
        ...item,
        all_skus: Array.isArray(item.all_skus) ? item.all_skus : item.all_skus.split(',')
      }));
      setPackagingOptions(formattedData);
    } catch (error) {
      console.error('Error fetching packaging options:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingPackaging) {
      setEditingPackaging(prev => ({ ...prev, [name]: value }));
    } else {
      if (name === 'sku_root') {
        // Ensure the SKU root always starts with 'SC-PACKAGING-'
        const newValue = value.startsWith('SC-PACKAGING-') ? value : `SC-PACKAGING-${value}`;
        setNewPackaging(prev => ({ ...prev, [name]: newValue }));
      } else {
        setNewPackaging(prev => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const packagingToSubmit = editingPackaging || newPackaging;
      
      // Format all_skus as a comma-separated string
      const formattedAllSkus = packagingToSubmit.all_skus.join(',');

      const url = editingPackaging 
        ? `https://silicreate-web-giver.silicreate.workers.dev/edit-packaging/${packagingToSubmit.sku_root}`
        : 'https://silicreate-web-giver.silicreate.workers.dev/add-packaging';
      
      const response = await fetch(url, {
        method: editingPackaging ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authToken')
        },
        body: JSON.stringify({ ...packagingToSubmit, all_skus: formattedAllSkus })
      });

      if (!response.ok) {
        throw new Error('Failed to submit packaging');
      }

      setIsModalOpen(false);
      setEditingPackaging(null);
      setNewPackaging({
        sku_root: 'SC-PACKAGING-',
        all_skus: [],
        name: '',
        description: ''
      });
      fetchPackagingOptions();
    } catch (error) {
      console.error('Error submitting packaging:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkuChange = (e, index) => {
    const updatedSkus = editingPackaging ? [...editingPackaging.all_skus] : [...newPackaging.all_skus];
    updatedSkus[index] = e.target.value;
    if (editingPackaging) {
      setEditingPackaging(prev => ({ ...prev, all_skus: updatedSkus }));
    } else {
      setNewPackaging(prev => ({ ...prev, all_skus: updatedSkus }));
    }
  };

  const removeSku = (index) => {
    if (editingPackaging) {
      const updatedSkus = editingPackaging.all_skus.filter((_, i) => i !== index);
      setEditingPackaging(prev => ({ ...prev, all_skus: updatedSkus }));
    } else {
      const updatedSkus = newPackaging.all_skus.filter((_, i) => i !== index);
      setNewPackaging(prev => ({ ...prev, all_skus: updatedSkus }));
    }
  };

  const addSku = () => {
    const skuRoot = editingPackaging ? editingPackaging.sku_root : newPackaging.sku_root;
    if (editingPackaging) {
      setEditingPackaging(prev => ({ ...prev, all_skus: [...prev.all_skus, `${skuRoot}-`] }));
    } else {
      setNewPackaging(prev => ({ ...prev, all_skus: [...prev.all_skus, `${skuRoot}-`] }));
    }
  };

  const handleEdit = (packaging) => {
    // Ensure all_skus is an array when editing
    const editedPackaging = {
      ...packaging,
      all_skus: Array.isArray(packaging.all_skus) ? packaging.all_skus : packaging.all_skus.split(',')
    };
    setEditingPackaging(editedPackaging);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!editingPackaging) return;

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this packaging option? This action cannot be undone."
    );

    if (confirmDelete) {
      try {
        setIsSubmitting(true);
        const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/delete-packaging/${editingPackaging.sku_root}`, {
          method: 'DELETE',
          headers: {
            'Authorization': localStorage.getItem('authToken')
          }
        });

        const responseText = await response.text();
        let responseData;
        try {
          responseData = JSON.parse(responseText);
        } catch (e) {
          // If the response is not valid JSON, use the text as the error message
          responseData = { error: responseText };
        }

        if (!response.ok) {
          throw new Error(responseData.error || 'Failed to delete packaging');
        }

        console.log('Delete successful:', responseData);

        setIsModalOpen(false);
        setEditingPackaging(null);
        fetchPackagingOptions();
      } catch (error) {
        console.error('Error deleting packaging:', error);
        alert(`Failed to delete packaging: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '1000px',
      display: 'flex',
      borderRadius: '1.5rem',
      padding: '2rem',
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  return (
    <div className="bg-white rounded-3xl border border-gray-100 p-0 min-h-[300px]">
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <>
          <table className="min-w-full bg-white rounded-3xl overflow-hidden">
            <thead className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white">
              <tr>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">SKU Root</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">All SKUs</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Name</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Description</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {packagingOptions.map((packaging) => (
                <tr key={packaging.sku_root} className="border-b border-gray-200">
                  <td className="text-left py-3 px-4">{packaging.sku_root}</td>
                  <td className="text-left py-1 px-1">
                    {(Array.isArray(packaging.all_skus) ? packaging.all_skus : packaging.all_skus.split(',')).map((sku, index) => (
                      <div key={index} className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                        {sku}
                      </div>
                    ))}
                  </td>
                  <td className="text-left py-3 px-4">{packaging.name}</td>
                  <td className="text-left py-3 px-4">{packaging.description}</td>
                  <td className="text-left py-3 px-4">
                    <button
                      onClick={() => handleEdit(packaging)}
                      className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            onClick={() => {
              setEditingPackaging(null);
              setIsModalOpen(true);
            }}
            className="mt-6 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300"
          >
            Add New Packaging
          </button>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {
              setIsModalOpen(false);
              setEditingPackaging(null);
            }}
            style={customStyles}
            contentLabel="Packaging Form"
          >
            <div className="flex-1 pr-8">
              <h2 className="text-3xl font-bold mb-6 text-gray-800">{editingPackaging ? 'Edit Packaging' : 'Add New Packaging'}</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sku_root">
                    SKU Root
                  </label>
                  <div className="flex items-center">
                    <span className="bg-gray-200 px-3 py-2 rounded-l-md text-gray-700 whitespace-nowrap">SC-PACKAGING-</span>
                    <input
                      className="shadow appearance-none border rounded-r-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="sku_root"
                      type="text"
                      placeholder="Enter SKU Root"
                      name="sku_root"
                      value={(editingPackaging ? editingPackaging.sku_root : newPackaging.sku_root).replace('SC-PACKAGING-', '')}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">All SKUs</label>
                  {(editingPackaging ? editingPackaging.all_skus : newPackaging.all_skus).map((sku, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="SKU"
                        value={sku}
                        onChange={(e) => handleSkuChange(e, index)}
                      />
                      <button
                        type="button"
                        className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        onClick={() => removeSku(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xl"
                    onClick={addSku}
                  >
                    +
                  </button>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={editingPackaging ? editingPackaging.name : newPackaging.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                    Description
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="description"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={editingPackaging ? editingPackaging.description : newPackaging.description}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex items-center justify-between mb-4">
                  <button
                    className={`bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="flex items-center">
                        <ThreeDots color="#FFFFFF" height={24} width={24} />
                        Processing...
                      </span>
                    ) : (
                      editingPackaging ? 'Update Packaging' : 'Add Packaging'
                    )}
                  </button>
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-full transition-all duration-300"
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setEditingPackaging(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>

              {editingPackaging && (
                <div className="text-right mt-4">
                  <button
                    className="text-xs bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                    type="button"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Deleting...' : 'Delete Packaging'}
                  </button>
                </div>
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default PackagingSettings;