import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { jsPDF } from "jspdf";
import { format } from 'date-fns'; // Make sure to install and import date-fns
import chillBottleImage from '../assets/images/chillBottle-trans.png';

const BottlingPlanner = ({ skuSales, getWeightFromSku, setBottlingPlan, setPackagingPlan }) => {
  const [totalKilograms, setTotalKilograms] = useState(400);
  const [unitCounts, setUnitCounts] = useState({});
  const [combineFBA, setCombineFBA] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);

  useEffect(() => {
    if (skuSales[365]) {
      const initialUnitCounts = calculateInitialUnitCounts(skuSales[365]);
      setUnitCounts(initialUnitCounts);
      updateTotalUnits(initialUnitCounts);
    }
  }, [skuSales, combineFBA]);

  const calculateInitialUnitCounts = (sales) => {
    let processedSales = sales;
    if (combineFBA) {
      processedSales = Object.values(sales.reduce((acc, sku) => {
        const baseSku = sku.sku.replace('-FBA', '');
        if (!acc[baseSku]) {
          acc[baseSku] = { ...sku, sku: baseSku, total_sold: 0 };
        }
        acc[baseSku].total_sold += sku.total_sold;
        return acc;
      }, {}));
    }

    const totalWeight = processedSales.reduce((sum, sku) => sum + (sku.total_sold * getWeightFromSku(sku.sku)), 0);
    const scaleFactor = totalKilograms / totalWeight;

    return processedSales.reduce((acc, sku) => {
      const weight = getWeightFromSku(sku.sku);
      acc[sku.sku] = Math.round(sku.total_sold * scaleFactor);
      return acc;
    }, {});
  };

  const handleUnitCountChange = (sku, value) => {
    const newUnitCounts = { ...unitCounts, [sku]: parseInt(value) };
    setUnitCounts(newUnitCounts);
    updateTotalUnits(newUnitCounts);
  };

  const updateTotalUnits = (newUnitCounts) => {
    const newTotal = Object.values(newUnitCounts).reduce((sum, count) => sum + count, 0);
    setTotalUnits(newTotal);
  };

  const handleTotalKilogramsChange = (value) => {
    setTotalKilograms(value);
    const newUnitCounts = calculateInitialUnitCounts(skuSales[365]);
    setUnitCounts(newUnitCounts);
    updateTotalUnits(newUnitCounts);
  };

  const calculateBottlingPlan = () => {
    const plan = {
      '250g': { partA: 0, partB: 0 },
      '500g': { partA: 0, partB: 0 },
      '1kg': { partA: 0, partB: 0 },
      '5kg': { partA: 0, partB: 0 }
    };
    let totalBottles = 0;
    let totalLabels = 0;

    Object.entries(unitCounts).forEach(([sku, count]) => {
      const weight = getWeightFromSku(sku);
      if (weight <= 0.5) {
        plan['250g'].partA += count;
        plan['250g'].partB += count;
      } else if (weight <= 1) {
        plan['500g'].partA += count;
        plan['500g'].partB += count;
      } else if (weight <= 2) {
        plan['1kg'].partA += count;
        plan['1kg'].partB += count;
      } else if (weight <= 4) {
        plan['1kg'].partA += count * 2;
        plan['1kg'].partB += count * 2;
      } else if (weight <= 10) {
        plan['5kg'].partA += count;
        plan['5kg'].partB += count;
      } else if (weight <= 20) {
        plan['5kg'].partA += count * 2;
        plan['5kg'].partB += count * 2;
      }
      totalBottles += count * 2;
    });

    // Calculate total labels
    totalLabels = Object.values(plan).reduce((sum, sizes) => sum + sizes.partA + sizes.partB, 0);

    setBottlingPlan({ ...plan, totalBottles, totalLabels });
  };

  const calculatePackagingPlan = () => {
    const plan = {};
    Object.entries(unitCounts).forEach(([sku, count]) => {
      if (combineFBA) {
        const standardSku = sku;
        const fbaSku = `${sku}-FBA`;
        const standardRatio = skuSales[365].find(s => s.sku === standardSku)?.total_sold || 0;
        const fbaRatio = skuSales[365].find(s => s.sku === fbaSku)?.total_sold || 0;
        const totalRatio = standardRatio + fbaRatio;

        if (totalRatio > 0) {
          plan[standardSku] = Math.round(count * (standardRatio / totalRatio));
          plan[fbaSku] = Math.round(count * (fbaRatio / totalRatio));
        } else {
          plan[standardSku] = count;
        }
      } else {
        plan[sku] = count;
      }
    });
    setPackagingPlan(plan);
  };

  const getBottleSize = (weight) => {
    if (weight <= 0.5) return '250g';
    if (weight <= 2) return '500g';
    if (weight <= 4) return '1kg';
    return '5kg';
  };

  const calculatePercentages = () => {
    const totalWeight = Object.entries(unitCounts).reduce((sum, [sku, count]) => sum + (count * getWeightFromSku(sku)), 0);
    return Object.entries(unitCounts).reduce((acc, [sku, count]) => {
      const weight = getWeightFromSku(sku);
      acc[sku] = {
        percentage: ((count * weight / totalWeight) * 100).toFixed(2),
        standardCount: count,
        fbaCount: 0
      };
      if (combineFBA) {
        const standardSku = sku;
        const fbaSku = `${sku}-FBA`;
        const standardRatio = skuSales[365].find(s => s.sku === standardSku)?.total_sold || 0;
        const fbaRatio = skuSales[365].find(s => s.sku === fbaSku)?.total_sold || 0;
        const totalRatio = standardRatio + fbaRatio;
        if (totalRatio > 0) {
          acc[sku].standardCount = Math.round(count * (standardRatio / totalRatio));
          acc[sku].fbaCount = Math.round(count * (fbaRatio / totalRatio));
        }
      }
      return acc;
    }, {});
  };

  const calculateTotals = () => {
    const calculatedTotalUnits = Object.values(unitCounts).reduce((sum, count) => sum + count, 0);
    const calculatedTotalKilograms = Object.entries(unitCounts).reduce((sum, [sku, count]) => {
      return sum + (count * getWeightFromSku(sku));
    }, 0);
    const calculatedTotalPercentage = Object.values(calculatePercentages()).reduce((sum, data) => sum + parseFloat(data.percentage), 0);
    return { calculatedTotalUnits, calculatedTotalKilograms, calculatedTotalPercentage };
  };

  const { calculatedTotalUnits, calculatedTotalKilograms, calculatedTotalPercentage } = calculateTotals();
  const isOverLimit = calculatedTotalKilograms > totalKilograms;

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold mb-4">Bottling Planner</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Total Kilograms to Bottle</label>
        <input
          type="number"
          value={totalKilograms}
          onChange={(e) => handleTotalKilogramsChange(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={combineFBA}
            onChange={() => setCombineFBA(!combineFBA)}
            className="mr-2"
          />
          Combine Standard and FBA SKUs
        </label>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-medium mb-2">SKU Units and Percentages</h3>
        {Object.entries(unitCounts).map(([sku, count]) => {
          const percentages = calculatePercentages();
          return (
            <div key={sku} className="flex items-center mb-2">
              <span className="w-1/4">{sku}</span>
              <input
                type="number"
                value={count}
                onChange={(e) => handleUnitCountChange(sku, e.target.value)}
                className="w-1/4 border border-gray-300 rounded-md shadow-sm p-1 mr-2"
              />
              <span className="mr-4">units</span>
              <span className="mr-4">{percentages[sku].percentage}%</span>
              {combineFBA && (
                <span className="text-sm text-gray-600">
                  (Standard: {percentages[sku].standardCount}, FBA: {percentages[sku].fbaCount})
                </span>
              )}
            </div>
          );
        })}
        <div className="mt-4">
          <div className={`font-bold ${calculatedTotalPercentage > 100 ? 'text-red-500' : ''}`}>
            Total Percentage: {calculatedTotalPercentage.toFixed(2)}%
          </div>
          <div className="font-bold">
            Total Units: {calculatedTotalUnits}
          </div>
          <div className={`font-bold ${isOverLimit ? 'text-red-500' : ''}`}>
            Total Kilograms: {calculatedTotalKilograms.toFixed(2)} kg
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          calculateBottlingPlan();
          calculatePackagingPlan();
        }}
        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
          isOverLimit ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={isOverLimit}
      >
        Calculate Plans
      </button>
    </div>
  );
};

const PastPlanDetails = ({ plan, getWeightFromSku }) => {
  if (!plan || !plan.packaging_plan || !plan.bottling_plan) {
    return <div>Error: Invalid plan data</div>;
  }

  const totalKilograms = Object.entries(plan.packaging_plan).reduce((sum, [sku, quantity]) => {
    return sum + (quantity * getWeightFromSku(sku));
  }, 0);

  return (
    <div className="mt-8 p-4 bg-gray-100 rounded-lg">
      <h3 className="text-xl font-semibold mb-4">Past Plan Details</h3>
      <p><strong>Date:</strong> {plan.run_timestamp ? format(new Date(plan.run_timestamp * 1000), 'PPP') : 'N/A'}</p>
      <p><strong>SKU Root:</strong> {plan.run_sku_root || 'N/A'}</p>
      <p><strong>Total Kilograms:</strong> {totalKilograms.toFixed(2)} kg</p>

      <div className="mt-4">
        <h4 className="text-lg font-medium mb-2">Bottling Plan:</h4>
        {Object.entries(plan.bottling_plan).map(([bottleSize, quantity]) => {
          if (bottleSize !== 'totalBottles' && bottleSize !== 'totalLabels' && quantity) {
            return (
              <div key={bottleSize} className="mb-2">
                <strong>{bottleSize}:</strong> {quantity.partA + quantity.partB} total (Part A: {quantity.partA}, Part B: {quantity.partB})
              </div>
            );
          }
          return null;
        })}
        <div className="mt-2">
          <strong>Total Bottles:</strong> {plan.bottling_plan.totalBottles || 'N/A'}
        </div>
        <div className="mt-2">
          <strong>Total Labels:</strong> {plan.bottling_plan.totalLabels || 'N/A'}
          {plan.bottling_plan.totalLabels && ` (Part A: ${plan.bottling_plan.totalLabels / 2}, Part B: ${plan.bottling_plan.totalLabels / 2})`}
        </div>
      </div>

      <div className="mt-4">
        <h4 className="text-lg font-medium mb-2">Packaging Plan:</h4>
        {Object.entries(plan.packaging_plan).map(([sku, quantity]) => (
          <div key={sku} className="mb-2">
            <strong>{sku}:</strong> {quantity} units
          </div>
        ))}
      </div>
    </div>
  );
};

const BottlingRun = () => {
  const [skuRoots, setSkuRoots] = useState([]);
  const [selectedSkuRoot, setSelectedSkuRoot] = useState('');
  const [skuSales, setSkuSales] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [combineFBA, setCombineFBA] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [customPeriods, setCustomPeriods] = useState([30, 90, 365]);
  const [removedSkus, setRemovedSkus] = useState([]);
  const [outliers, setOutliers] = useState([]);
  const [pastPlans, setPastPlans] = useState([]);
  const [selectedPastPlan, setSelectedPastPlan] = useState(null);
  const [bottlingPlan, setBottlingPlan] = useState({});
  const [packagingPlan, setPackagingPlan] = useState({});

  // Add this new state for showing the plan breakdown
  const [showPlanBreakdown, setShowPlanBreakdown] = useState(false);

  useEffect(() => {
    fetchSkuRoots();
    fetchPastPlans();
  }, []);

  const fetchSkuRoots = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/sku-roots', {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch SKU roots');
      }
      const data = await response.json();
      setSkuRoots(data);
    } catch (error) {
      console.error('Error fetching SKU roots:', error);
    }
  };

  const fetchSkuSales = async (skuRoot) => {
    setIsLoading(true);
    try {
      const periods = [...customPeriods, 0];
      const salesData = {};
      for (const period of periods) {
        const endDate = new Date();
        const startDate = period === 0 ? new Date(0) : new Date(endDate.getTime() - (period * 24 * 60 * 60 * 1000));
        const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/sku-sales/${skuRoot}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {
          headers: {
            'Authorization': localStorage.getItem('authToken')
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch SKU sales');
        }
        const data = await response.json();
        salesData[period] = data;
      }
      setSkuSales(salesData);
      setRemovedSkus([]); // Reset removed SKUs when fetching new data

      // Fetch outliers
      const outliersResponse = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/sku-outliers/${skuRoot}`, {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });
      if (outliersResponse.ok) {
        const outliersData = await outliersResponse.json();
        setOutliers(outliersData);
      }
    } catch (error) {
      console.error('Error fetching SKU sales:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkuRootChange = (e) => {
    const selectedRoot = e.target.value;
    setSelectedSkuRoot(selectedRoot);
    if (selectedRoot) {
      fetchSkuSales(selectedRoot);
    } else {
      setSkuSales({});
    }
  };

  const getWeightFromSku = (sku) => {
    const weightMatch = sku.match(/-(\d+(?:\.\d+)?)(?:-FBA)?$/);
    if (weightMatch) {
      return parseFloat(weightMatch[1]);
    }
    return 0; // Default case if weight can't be determined
  };

  const combineSkus = (skus) => {
    return skus.reduce((acc, sku) => {
      const baseSku = sku.sku.replace('-FBA', '');
      if (acc[baseSku]) {
        acc[baseSku].total_sold += sku.total_sold;
      } else {
        acc[baseSku] = { ...sku, sku: baseSku };
      }
      return acc;
    }, {});
  };

  const calculatePercentages = (period) => {
    if (!skuSales[period] || !Array.isArray(skuSales[period])) {
      console.error(`Invalid data for period ${period}`);
      return [];
    }

    let processedSales = combineFBA ? Object.values(combineSkus(skuSales[period])) : skuSales[period];
    processedSales = processedSales.filter(sku => !removedSkus.includes(sku.sku));
    
    const totalWeightSold = processedSales.reduce((sum, sku) => sum + (sku.total_sold * getWeightFromSku(sku.sku)), 0);
    
    return processedSales.map(sku => {
      const weight = getWeightFromSku(sku.sku);
      const weightSold = sku.total_sold * weight;
      return {
        ...sku,
        weight,
        weightSold,
        percentage: totalWeightSold > 0 ? ((weightSold / totalWeightSold) * 100).toFixed(2) : '0.00'
      };
    });
  };

  const getTotalWeightSold = (period) => {
    if (!skuSales[period] || !Array.isArray(skuSales[period])) {
      console.error(`Invalid data for period ${period}`);
      return 0;
    }

    let processedSales = combineFBA ? Object.values(combineSkus(skuSales[period])) : skuSales[period];
    processedSales = processedSales.filter(sku => !removedSkus.includes(sku.sku));
    return processedSales.reduce((sum, sku) => sum + (sku.total_sold * getWeightFromSku(sku.sku)), 0);
  };

  const handleCustomPeriodChange = (index, value) => {
    const newPeriods = [...customPeriods];
    newPeriods[index] = parseInt(value);
    setCustomPeriods(newPeriods);
  };

  const handleRemoveSku = (skuToRemove) => {
    setRemovedSkus(prev => [...prev, skuToRemove]);
  };

  const handleAddBackSku = (skuToAdd) => {
    setRemovedSkus(prev => prev.filter(sku => sku !== skuToAdd));
  };

  const fetchPastPlans = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/bottling-plans', {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch past bottling plans');
      }
      const data = await response.json();
      setPastPlans(data);
    } catch (error) {
      console.error('Error fetching past bottling plans:', error);
    }
  };

  const savePlan = async () => {
    try {
      const planData = {
        skuRoot: selectedSkuRoot,
        bottlingPlan,
        packagingPlan,
        timestamp: Math.floor(Date.now() / 1000) // Current timestamp in seconds
      };
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/save-bottling-plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authToken')
        },
        body: JSON.stringify(planData)
      });
      if (!response.ok) {
        throw new Error('Failed to save bottling plan');
      }
      alert('Bottling plan saved successfully!');
      fetchPastPlans(); // Refresh the list of past plans
    } catch (error) {
      console.error('Error saving bottling plan:', error);
      alert('Failed to save bottling plan');
    }
  };

  const updatePlanWithFinishedAmounts = async (planId, finishedAmounts) => {
    try {
      const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/update-bottling-plan/${planId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authToken')
        },
        body: JSON.stringify({ finishedAmounts })
      });
      if (!response.ok) {
        throw new Error('Failed to update bottling plan');
      }
      alert('Bottling plan updated successfully!');
      fetchPastPlans(); // Refresh the list of past plans
    } catch (error) {
      console.error('Error updating bottling plan:', error);
      alert('Failed to update bottling plan');
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date();
    const formattedDate = format(currentDate, 'MMM-yyyy');
    const fileName = `${selectedSkuRoot}_${formattedDate}.pdf`;

    const totalKilograms = Object.entries(packagingPlan).reduce((sum, [sku, quantity]) => {
      return sum + (quantity * getWeightFromSku(sku));
    }, 0);

    // Add title details on the left side
    doc.setFontSize(16);
    doc.text("Bottling Plan Report", 20, 20);
    doc.setFontSize(12);
    doc.text(`Date: ${format(currentDate, 'PPP')}`, 20, 30);
    doc.text(`SKU: ${selectedSkuRoot}`, 20, 40);
    doc.text(`Total Kilograms: ${totalKilograms.toFixed(2)} kg`, 20, 50);

    // Add image to the top right
    const img = new Image();
    img.src = chillBottleImage
    doc.addImage(img, 'PNG', 80, 15, 150, 150);

    // Bottling Plan
    doc.setFontSize(14);
    doc.text("Bottling Plan:", 20, 70);
    let yOffset = 80;
    Object.entries(bottlingPlan).forEach(([bottleSize, quantity]) => {
      if (bottleSize !== 'totalBottles' && bottleSize !== 'totalLabels') {
        doc.setFontSize(12);
        doc.text(`${bottleSize} bottles:`, 30, yOffset);
        doc.text(`[  ] Part A: ${quantity.partA}`, 40, yOffset + 10);
        doc.text(`[  ] Part B: ${quantity.partB}`, 40, yOffset + 20);
        yOffset += 30;
      }
    });

    // Add total labels information
    const totalLabels = bottlingPlan.totalLabels || 0;
    doc.text(`Total Labels: ${totalLabels} (A: ${totalLabels / 2}, B: ${totalLabels / 2})`, 30, yOffset);
    yOffset += 15;

    // Packaging Plan in two columns
    doc.setFontSize(14);
    doc.text("Packaging Plan:", 20, yOffset);
    yOffset += 10;
    const packagingEntries = Object.entries(packagingPlan);
    const midPoint = Math.ceil(packagingEntries.length / 2);
    
    doc.setFontSize(12);
    let leftColumnY = yOffset;
    let rightColumnY = yOffset;

    packagingEntries.forEach(([sku, quantity], index) => {
      if (index < midPoint) {
        doc.text(`[  ] ${sku}: ${quantity} units`, 30, leftColumnY);
        leftColumnY += 10;
      } else {
        doc.text(`[  ] ${sku}: ${quantity} units`, 110, rightColumnY);
        rightColumnY += 10;
      }
    });

    doc.save(fileName);
  };

  // Add this new function to format the plan display
  const formatPlanDisplay = (plan) => {
    return Object.entries(plan).map(([key, value]) => {
      if (key === 'totalBottles' || key === 'totalLabels') return null;
      return (
        <div key={key} className="mb-2">
          <strong>{key}:</strong> {value.partA + value.partB} total (Part A: {value.partA}, Part B: {value.partB})
        </div>
      );
    });
  };

  // Modify the select onChange handler
  const handlePastPlanSelection = async (e) => {
    const selectedPlanId = e.target.value;
    if (!selectedPlanId) {
      setSelectedPastPlan(null);
      setBottlingPlan({});
      setPackagingPlan({});
      return;
    }

    try {
      const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/bottling-plan/${selectedPlanId}`, {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch plan details');
      }

      const planData = await response.json();
      console.log(planData);
      setSelectedPastPlan(planData);
      
      if (planData.run_bottling_breakdown_planned && planData.run_packaging_breakdown_planned) {
        const bottlingPlan = JSON.parse(planData.run_bottling_breakdown_planned);
        const packagingPlan = JSON.parse(planData.run_packaging_breakdown_planned);
        setBottlingPlan(bottlingPlan);
        setPackagingPlan(packagingPlan);
      } else {
        setBottlingPlan({});
        setPackagingPlan({});
      }
    } catch (error) {
      console.error('Error fetching plan details:', error);
      alert('Failed to load plan details');
    }
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Bottling Run</h1>
          <div className="flex items-center space-x-4">
            <select
              onChange={handlePastPlanSelection}
              className="border rounded px-2 py-1"
            >
              <option value="">Select a past plan</option>
              {pastPlans.map((plan) => (
                <option key={plan.bottling_run_id} value={plan.bottling_run_id}>
                  {format(new Date(plan.run_timestamp * 1000), 'PPP')} - {plan.run_sku_root}
                </option>
              ))}
            </select>
            <button
              onClick={() => setShowSettings(!showSettings)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {showSettings ? 'Hide Settings' : 'Show Settings'}
            </button>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={combineFBA}
                onChange={() => setCombineFBA(!combineFBA)}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Combine FBA</span>
            </label>
          </div>
        </div>
        {showSettings && (
          <div className="mb-6 p-4 bg-gray-100 rounded-lg">
            <h2 className="text-lg font-semibold mb-2">Custom Periods (days)</h2>
            {customPeriods.map((period, index) => (
              <div key={index} className="flex items-center mb-2">
                <label className="mr-2">Period {index + 1}:</label>
                <input
                  type="number"
                  value={period}
                  onChange={(e) => handleCustomPeriodChange(index, e.target.value)}
                  className="border rounded px-2 py-1 w-20"
                />
              </div>
            ))}
          </div>
        )}
        <div className="mb-6">
          <label htmlFor="skuRoot" className="block text-sm font-medium text-gray-700">Select SKU Root</label>
          <select
            id="skuRoot"
            value={selectedSkuRoot}
            onChange={handleSkuRootChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">Select a SKU Root</option>
            {skuRoots.map((skuRoot) => (
              <option key={skuRoot.sku_root} value={skuRoot.sku_root}>
                {skuRoot.sku_root} - {skuRoot.product_name}
              </option>
            ))}
          </select>
        </div>

        {isLoading ? (
          <div className="text-center">
            <div className="spinner"></div>
            <p>Loading sales data...</p>
          </div>
        ) : selectedSkuRoot && Object.keys(skuSales).length > 0 ? (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Sales Distribution for {selectedSkuRoot}</h2>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Weight (kg)</th>
                  {[...customPeriods, 0].map((period) => (
                    <th key={period} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {period === 0 ? 'Since Inception' : `${period} Days`}
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {calculatePercentages(0).map((sku) => (
                  <tr key={sku.sku}>
                    <td className="px-6 py-4 whitespace-nowrap">{sku.sku}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{sku.weight}</td>
                    {[...customPeriods, 0].map((period) => {
                      const periodData = calculatePercentages(period).find(s => s.sku === sku.sku);
                      return (
                        <td key={period} className="px-6 py-4 whitespace-nowrap">
                          {periodData ? `${periodData.percentage}%` : 'N/A'}
                        </td>
                      );
                    })}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleRemoveSku(sku.sku)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Outliers section */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">Outliers and Anomalies</h3>
              {outliers.length > 0 ? (
                <ul className="list-disc pl-5">
                  {outliers.map((outlier, index) => (
                    <li key={index} className="mb-2">
                      {outlier.message}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No outliers or anomalies detected.</p>
              )}
            </div>

            {removedSkus.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Removed SKUs</h3>
                <ul>
                  {removedSkus.map(sku => (
                    <li key={sku} className="flex items-center justify-between bg-gray-100 px-4 py-2 rounded mb-2">
                      <span>{sku}</span>
                      <button
                        onClick={() => handleAddBackSku(sku)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Add Back
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-4">
              {[...customPeriods, 0].map((period) => (
                <p key={period} className="mb-2">
                  Total Weight Sold ({period === 0 ? 'Since Inception' : `${period} Days`}): {getTotalWeightSold(period).toFixed(2)} kg
                </p>
              ))}
            </div>
          </div>
        ) : selectedSkuRoot ? (
          <p>No sales data available for the selected SKU root.</p>
        ) : null}
        
        {selectedSkuRoot && Object.keys(skuSales).length > 0 && (
          <BottlingPlanner 
            skuSales={skuSales} 
            getWeightFromSku={getWeightFromSku}
            setBottlingPlan={setBottlingPlan}
            setPackagingPlan={setPackagingPlan}
          />
        )}
        
        {/* Add this new section to display the plan breakdown */}
        {showPlanBreakdown && Object.keys(bottlingPlan).length > 0 && (
          <div className="mt-8 p-4 bg-gray-100 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Plan Breakdown</h3>
            
            <div className="mb-4">
              <h4 className="text-lg font-medium mb-2">Bottling Plan:</h4>
              {formatPlanDisplay(bottlingPlan)}
              <div className="mt-2">
                <strong>Total Bottles:</strong> {bottlingPlan.totalBottles}
              </div>
              <div className="mt-2">
                <strong>Total Labels to Print:</strong> {bottlingPlan.totalLabels} (Part A: {bottlingPlan.totalLabels / 2}, Part B: {bottlingPlan.totalLabels / 2})
              </div>
            </div>
            
            <div>
              <h4 className="text-lg font-medium mb-2">Packaging Plan:</h4>
              {Object.entries(packagingPlan).map(([sku, quantity]) => (
                <div key={sku} className="mb-2">
                  <strong>{sku}:</strong> {quantity} units
                </div>
              ))}
            </div>
          </div>
        )}
        
        {Object.keys(bottlingPlan).length > 0 && (
          <div className="mt-8 flex space-x-4">
            <button
              onClick={() => setShowPlanBreakdown(!showPlanBreakdown)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {showPlanBreakdown ? 'Hide Plan Breakdown' : 'Show Plan Breakdown'}
            </button>
            <button
              onClick={savePlan}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Save Bottling Plan
            </button>
            <button
              onClick={generatePDF}
              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
            >
              Generate PDF Report
            </button>
          </div>
        )}
        
        {selectedPastPlan && (
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Update Finished Amounts</h2>
            {/* Add form inputs for updating finished amounts */}
            <button
              onClick={() => updatePlanWithFinishedAmounts(selectedPastPlan.id, /* finished amounts */)}
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
            >
              Update Plan
            </button>
          </div>
        )}

        {selectedPastPlan && <PastPlanDetails plan={selectedPastPlan} getWeightFromSku={getWeightFromSku} />}
      </div>
    </Layout>
  );
};

export default BottlingRun;