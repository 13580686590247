import React from 'react';
import Modal from 'react-modal';

const ManagePackagingModal = ({
    isOpen,
    onClose,
    shipmentPackagingOptions,
    isLoadingPackagingOptions,
    handleEditPackaging,
    handleDeletePackaging,
    newPackaging,
    handleNewPackagingChange,
    handleSaveNewPackaging,
    isAddingPackaging
}) => {
    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '1200px',
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: '8px',
            padding: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customModalStyles}
            contentLabel="Manage Shipment Packaging"
        >
            <div className="relative">
                <h2 className="text-2xl font-bold mb-4">Manage Shipment Packaging</h2>
                <button 
                    onClick={onClose}
                    className="absolute top-0 right-0 text-gray-600 hover:text-gray-800"
                >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div className="overflow-x-auto">
                    {isLoadingPackagingOptions ? (
                        <div className="flex justify-center items-center h-20">
                            <svg className="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dimensions</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Weight</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {shipmentPackagingOptions.filter(option => option.type === 'CUSTOM').map(option => (
                                    <tr key={option.code}>
                                        <td className="px-6 py-4 whitespace-nowrap">{option.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {option.length && option.width && option.height 
                                                ? `${option.length}x${option.width}x${option.height}` 
                                                : 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {option.max_weight ? `${option.max_weight}kg` : 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                            <button 
                                                onClick={() => handleEditPackaging(option)}
                                                className="text-indigo-600 hover:text-indigo-900 mr-2"
                                            >
                                                Edit
                                            </button>
                                            <button 
                                                onClick={() => handleDeletePackaging(option.code)}
                                                className="text-red-600 hover:text-red-900"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="mt-6 border-t pt-4">
                    <h3 className="text-lg font-semibold mb-2">Add New Custom Box Size</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <input
                            type="text"
                            placeholder="Name"
                            value={newPackaging.name}
                            onChange={(e) => handleNewPackagingChange('name', e.target.value)}
                            className="border rounded px-2 py-1"
                        />
                        <input
                            type="number"
                            placeholder="Length (cm)"
                            value={newPackaging.length}
                            onChange={(e) => handleNewPackagingChange('length', e.target.value)}
                            className="border rounded px-2 py-1"
                        />
                        <input
                            type="number"
                            placeholder="Width (cm)"
                            value={newPackaging.width}
                            onChange={(e) => handleNewPackagingChange('width', e.target.value)}
                            className="border rounded px-2 py-1"
                        />
                        <input
                            type="number"
                            placeholder="Height (cm)"
                            value={newPackaging.height}
                            onChange={(e) => handleNewPackagingChange('height', e.target.value)}
                            className="border rounded px-2 py-1"
                        />
                        <input
                            type="number"
                            placeholder="Weight (kg)"
                            value={newPackaging.maxWeight}
                            onChange={(e) => handleNewPackagingChange('maxWeight', e.target.value)}
                            className="border rounded px-2 py-1"
                        />
                    </div>
                    <button 
                        onClick={handleSaveNewPackaging}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
                        disabled={isAddingPackaging}
                    >
                        {isAddingPackaging ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Adding...
                            </>
                        ) : 'Add New Packaging'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ManagePackagingModal;