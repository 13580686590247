import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import chillBottleTransparent from '../assets/images/chillBottle-trans.png';

const Header = () => {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const [orderSummaries, setOrderSummaries] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isSummaryDropdownOpen, setIsSummaryDropdownOpen] = useState(false);
  const headerRef = useRef(null);
  const summaryDropdownRef = useRef(null);

  const dropdownItems = [
    { name: 'Orders', items: ['Ship Orders', 'Direct Orders', 'Order History', 'Invoicer'] },
    { name: 'Analytics', items: ['Sales Report', 'Product Performance', 'Customer Insights'] },
    { name: 'Planning', items: ['Bottling Run', 'Warehouse Stock', 'Sell Rate'] },
  ];

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setActiveDropdown(null);
        setIsMobileMenuOpen(false);
      }
      if (summaryDropdownRef.current && !summaryDropdownRef.current.contains(event.target)) {
        setIsSummaryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        if (window.scrollY > 10) {
          headerRef.current.classList.add('floating');
        } else {
          headerRef.current.classList.remove('floating');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchOrderSummary = async () => {
      try {
        const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/order-processing-summary', {
          headers: {
            'Authorization': localStorage.getItem('authToken'),
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Received order summaries:', data);
          if (Array.isArray(data) && data.length > 0) {
            setOrderSummary(data[0]);
            setOrderSummaries(data.slice(0, 5));
          } else {
            console.error('No order summaries found');
          }
        } else {
          console.error('Failed to fetch order summary');
        }
      } catch (error) {
        console.error('Error fetching order summary:', error);
      }
    };

    fetchOrderSummary();
    const intervalId = setInterval(fetchOrderSummary, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const response = await fetch('https://silicreate-data-gobbler.silicreate.workers.dev/trigger', {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('authToken'),
          'numberOfDays': '4',
          'Content-Type': 'application/json',  // Add this line
        },
      });
      if (response.ok) {
        // Refresh the order summary after triggering the update
        const summaryResponse = await fetch('https://silicreate-web-giver.silicreate.workers.dev/order-processing-summary', {
          headers: {
            'Authorization': localStorage.getItem('authToken'),
          },
        });
        if (summaryResponse.ok) {
          const data = await summaryResponse.json();
          setOrderSummary(data[0]);
          setOrderSummaries(data);
        }
      } else {
        console.error('Failed to refresh data');
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getRelativeTime = (timestamp) => {
    const now = Date.now();
    const timestampMs = Number(timestamp) * 1000; // Convert seconds to milliseconds
    const diff = now - timestampMs;
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;

    if (diff < minute) {
      return 'just now';
    } else if (diff < hour) {
      const minutes = Math.floor(diff / minute);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diff < day) {
      const hours = Math.floor(diff / hour);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diff / day);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000); // Convert seconds to milliseconds
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  };

  const formatMessage = (message) => {
    const regex = /Processed orders - eBay: (\d+), Squarespace: (\d+), Amazon: (\d+)/;
    const match = message.match(regex);
    if (match) {
      return (
        <span className="text-xs">
          <span className="font-semibold">E:</span>{match[1]} 
          <span className="font-semibold ml-1">S:</span>{match[2]} 
          <span className="font-semibold ml-1">A:</span>{match[3]}
        </span>
      );
    }
    return <span className="text-xs">{message}</span>;
  };

  return (
    <header ref={headerRef} className="fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out p-2 rounded-full flex items-center justify-between bg-black bg-opacity-80 mx-2 my-1 sm:mx-4 sm:my-2">
      <div className="flex items-center">
        <div className="flex items-center cursor-pointer" onClick={() => {
          navigate('/');
          setActiveDropdown(null);
          setIsMobileMenuOpen(false);
        }}>
          <img src={chillBottleTransparent} alt="Chill Bottle" className="h-6 w-6 sm:h-8 sm:w-8 mr-2" />
          <h1 className="text-xl sm:text-2xl font-bold text-white">SiliHUB</h1>
        </div>
      </div>
      <div className="flex-grow mx-2 sm:mx-4 text-center flex items-center justify-center">
        {orderSummary && (
          <div className="relative" ref={summaryDropdownRef}>
            <div 
              onClick={() => setIsSummaryDropdownOpen(!isSummaryDropdownOpen)}
              className={`text-xxs sm:text-xs font-medium rounded-full px-1 sm:px-2 py-1 cursor-pointer flex items-center ${
                orderSummary.status.toUpperCase() === 'SUCCESS' ? 'bg-green-500' : 'bg-red-500'
              }`}
            >
              <span className="mr-1">
                {orderSummary.status.toUpperCase() === 'SUCCESS' ? '✓' : '⚠'}
              </span>
              <span className="hidden sm:inline">
                {orderSummary.status.toUpperCase() === 'SUCCESS' ? 'Orders Processed' : 'Processing Issues'}
              </span>
              <span className="ml-1 text-xxs">
                {getRelativeTime(orderSummary.timestamp)}
              </span>
            </div>
            {isSummaryDropdownOpen && (
              <div className="absolute left-0 mt-2 w-full sm:w-[28rem] bg-white rounded-md shadow-lg z-10">
                <div className="p-2 sm:p-4">
                  <h2 className="text-base sm:text-lg font-bold mb-2">Order Processing Summaries</h2>
                  <div className="overflow-x-auto">
                    <table className="w-full mb-4">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="px-2 sm:px-3 py-1 sm:py-2 text-left text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                          <th className="px-2 sm:px-3 py-1 sm:py-2 text-left text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                          <th className="px-2 sm:px-3 py-1 sm:py-2 text-left text-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {orderSummaries.map((summary, index) => (
                          <tr key={index}>
                            <td className="px-2 sm:px-3 py-1 sm:py-2 whitespace-nowrap">
                              <div className="text-xxs sm:text-sm font-medium text-gray-900">{getRelativeTime(summary.timestamp)}</div>
                              <div className="text-xxs sm:text-xs text-gray-500">{formatDate(summary.timestamp)}</div>
                            </td>
                            <td className="px-2 sm:px-3 py-1 sm:py-2 whitespace-nowrap">
                              <span className={`px-1 sm:px-2 inline-flex text-xxs sm:text-xs leading-5 font-semibold rounded-full ${
                                summary.status.toUpperCase() === 'SUCCESS' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                              }`}>
                                {summary.status.toUpperCase() === 'SUCCESS' ? '✓' : '✗'}
                              </span>
                            </td>
                            <td className="px-2 sm:px-3 py-1 sm:py-2 whitespace-nowrap">
                              {formatMessage(summary.message)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="px-2 sm:px-4 py-2 sm:py-3 bg-gray-50 text-right sm:px-6 rounded-b-md">
                  <button
                    onClick={handleRefresh}
                    disabled={isRefreshing}
                    className={`w-full px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      isRefreshing ? 'bg-purple-400 cursor-not-allowed' : 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500'
                    }`}
                  >
                    {isRefreshing ? 'Refreshing...' : 'Refresh (this can take a minute...)'}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <nav className="hidden md:flex items-center space-x-2">
        {dropdownItems.map((item, index) => (
          <div key={item.name} className="relative">
            <button
              onClick={() => toggleDropdown(index)}
              className="px-2 sm:px-3 py-1 text-xs sm:text-sm font-medium text-white hover:bg-purple-700 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-black"
            >
              {item.name}
            </button>
            {activeDropdown === index && (
              <ul className="absolute right-0 mt-2 w-40 sm:w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {item.items.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <a
                      href={`/${item.name.toLowerCase()}/${String(subItem).trim().toLowerCase().replace(/\s+/g, '-')}`}
                      className="block px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm text-gray-700 hover:bg-purple-100"
                    >
                      {typeof subItem === 'string' ? subItem : subItem.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
        <button
          onClick={() => navigate('/settings')}
          className="px-2 sm:px-3 py-1 text-xs sm:text-sm font-medium text-white hover:bg-purple-700 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-black"
        >
          Settings
        </button>
        <button
          onClick={handleLogout}
          className="px-2 sm:px-3 py-1 text-xs sm:text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-black"
        >
          Log Out
        </button>
      </nav>
      <button
        onClick={toggleMobileMenu}
        className="md:hidden px-2 sm:px-3 py-1 text-xs sm:text-sm font-medium text-white hover:bg-purple-700 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-black"
      >
        Menu
      </button>
      {isMobileMenuOpen && (
        <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-md shadow-lg md:hidden">
          {dropdownItems.map((item, index) => (
            <div key={index} className="px-2 py-2 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
              <ul className="mt-2 space-y-2">
                {item.items.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <a
                      href={`/${item.name.toLowerCase()}/${String(subItem).trim().toLowerCase().replace(/\s+/g, '-')}`}
                      className="block px-3 py-1 text-xs text-gray-700 hover:bg-purple-100 rounded-md"
                    >
                      {typeof subItem === 'string' ? subItem : subItem.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="px-2 py-2">
            <button
              onClick={() => {
                navigate('/settings');
                setIsMobileMenuOpen(false);
              }}
              className="block w-full text-left px-3 py-1 text-xs text-gray-700 hover:bg-purple-100 rounded-md"
            >
              Settings
            </button>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-3 py-1 mt-2 text-xs text-white bg-red-500 hover:bg-red-600 rounded-md"
            >
              Log Out
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
