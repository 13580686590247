import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ThreeDots } from 'react-loader-spinner'; // Import the loader

Modal.setAppElement('#root');

const ProductsSettings = () => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    sku_root: '',
    all_skus: [],
    product_name: '',
    product_description: '',
    lead_time: ''
  });
  const [useAutoSKUs, setUseAutoSKUs] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true); // Set loading to true when fetching starts
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/get-products', {
        headers: {
          'Authorization': localStorage.getItem('authToken')
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data.map(product => ({
        ...product,
        all_skus: product.all_skus.split(',')
      })));
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false); // Set loading to false when fetching ends
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingProduct) {
      setEditingProduct(prev => ({ ...prev, [name]: value }));
    } else {
      setNewProduct(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const productToSubmit = editingProduct || newProduct;
      
      // Format all_skus as a comma-separated string
      const formattedAllSkus = `"${productToSubmit.all_skus.join(',')}"`;

      // Prepare the data to send
      const dataToSend = useAutoSKUs ? 
        { ...productToSubmit, all_skus: undefined } : 
        { ...productToSubmit, all_skus: formattedAllSkus };

      const url = editingProduct 
        ? `https://silicreate-web-giver.silicreate.workers.dev/edit-product/${productToSubmit.sku_root}`
        : useAutoSKUs
          ? 'https://silicreate-web-giver.silicreate.workers.dev/add-product-auto-skus'
          : 'https://silicreate-web-giver.silicreate.workers.dev/add-product';
      
      const response = await fetch(url, {
        method: editingProduct ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authToken')
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        throw new Error('Failed to submit product');
      }

      const result = await response.json();

      // Update the local state with the new product data
      if (useAutoSKUs) {
        setProducts(prevProducts => [...prevProducts, {
          ...result.product,
          all_skus: result.product.all_skus.split(',')
        }]);
      } else {
        setProducts(prevProducts => [...prevProducts, {
          ...productToSubmit,
          all_skus: productToSubmit.all_skus // Keep as array in local state
        }]);
      }

      setIsModalOpen(false);
      setEditingProduct(null);
      setNewProduct({
        sku_root: '',
        all_skus: [],
        product_name: '',
        product_description: '',
        lead_time: ''
      });
    } catch (error) {
      console.error('Error submitting product:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkuChange = (e, index) => {
    const updatedSkus = editingProduct ? [...editingProduct.all_skus] : [...newProduct.all_skus];
    updatedSkus[index] = e.target.value;
    if (editingProduct) {
      setEditingProduct(prev => ({ ...prev, all_skus: updatedSkus }));
    } else {
      setNewProduct(prev => ({ ...prev, all_skus: updatedSkus }));
    }
  };

  const removeSku = (index) => {
    if (editingProduct) {
      const updatedSkus = editingProduct.all_skus.filter((_, i) => i !== index);
      setEditingProduct(prev => ({ ...prev, all_skus: updatedSkus }));
    } else {
      const updatedSkus = newProduct.all_skus.filter((_, i) => i !== index);
      setNewProduct(prev => ({ ...prev, all_skus: updatedSkus }));
    }
  };

  const addSku = () => {
    if (editingProduct) {
      setEditingProduct(prev => ({ ...prev, all_skus: [...prev.all_skus, ''] }));
    } else {
      setNewProduct(prev => ({ ...prev, all_skus: [...prev.all_skus, ''] }));
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!editingProduct) return;

    const confirmDelete = window.confirm(
      "⚠️ DANGER: You are about to delete this SKU root and all associated SKUs. ⚠️\n\n" +
      "This action should ONLY be performed if:\n" +
      "1. You have JUST created this SKU root\n" +
      "2. It was created by mistake\n" +
      "3. It has NEVER been used in any orders or inventory\n\n" +
      "Deleting an established SKU will cause SEVERE ISSUES with existing orders, inventory, and analytics.\n\n" +
      "Are you absolutely sure you want to proceed?"
    );

    if (confirmDelete) {
      try {
        const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/delete-product/${editingProduct.sku_root}`, {
          method: 'DELETE',
          headers: {
            'Authorization': localStorage.getItem('authToken')
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete product');
        }

        setIsModalOpen(false);
        setEditingProduct(null);
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '1000px',
      display: 'flex',
      borderRadius: '1.5rem',
      padding: '2rem',
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  return (
    <div className="bg-white rounded-3xl border border-gray-100 p-0 min-h-[300px]">
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <>
          <table className="min-w-full bg-white rounded-3xl overflow-hidden">
            <thead className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white">
              <tr>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">SKU Root</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">All SKUs</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Name</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Description</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Lead Time</th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {products.map((product) => (
                <tr key={product.sku_root} className="border-b border-gray-200">
                  <td className="text-left py-3 px-4">{product.sku_root}</td>
                  <td className="text-left py-1 px-1">
                    {product.all_skus.map((sku, index) => (
                      <div key={index} className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                        {sku}
                      </div>
                    ))}
                  </td>
                  <td className="text-left py-3 px-4">{product.product_name}</td>
                  <td className="text-left py-3 px-4">{product.product_description}</td>
                  <td className="text-left py-3 px-4">{product.lead_time}</td>
                  <td className="text-left py-3 px-4">
                    <button
                      onClick={() => handleEdit(product)}
                      className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            onClick={() => {
              setEditingProduct(null);
              setIsModalOpen(true);
            }}
            className="mt-6 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300"
          >
            Add New Product
          </button>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => {
              setIsModalOpen(false);
              setEditingProduct(null);
            }}
            style={customStyles}
            contentLabel="Product Form"
          >
            <div className="flex-1 pr-8">
              <h2 className="text-3xl font-bold mb-6 text-gray-800">{editingProduct ? 'Edit Product' : 'Add New Product'}</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sku_root">
                    SKU Root
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="sku_root"
                    type="text"
                    placeholder="SKU Root"
                    name="sku_root"
                    value={editingProduct ? editingProduct.sku_root : newProduct.sku_root}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">All SKUs</label>
                  {(editingProduct ? editingProduct.all_skus : newProduct.all_skus).map((sku, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="SKU"
                        value={sku}
                        onChange={(e) => handleSkuChange(e, index)}
                      />
                      <button
                        type="button"
                        className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                        onClick={() => removeSku(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xl"
                    onClick={addSku}
                  >
                    +
                  </button>
                </div>

                {!editingProduct && (
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={useAutoSKUs}
                        onChange={(e) => setUseAutoSKUs(e.target.checked)}
                        className="mr-2"
                      />
                      Auto-generate SKUs
                    </label>
                  </div>
                )}
              </form>
            </div>

            <div className="flex-1 pl-8 border-l flex flex-col">
              <form onSubmit={handleSubmit} className="flex-grow">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product_name">
                    Product Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="product_name"
                    type="text"
                    placeholder="Product Name"
                    name="product_name"
                    value={editingProduct ? editingProduct.product_name : newProduct.product_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product_description">
                    Product Description
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="product_description"
                    type="text"
                    placeholder="Product Description"
                    name="product_description"
                    value={editingProduct ? editingProduct.product_description : newProduct.product_description}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lead_time">
                    Lead Time (days)
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lead_time"
                    type="number"
                    placeholder="Lead Time (days)"
                    name="lead_time"
                    value={editingProduct ? editingProduct.lead_time : newProduct.lead_time}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex items-center justify-between mb-4">
                  <button
                    className={`bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-6 rounded-full transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <span className="flex items-center">
                        <ThreeDots color="#FFFFFF" height={24} width={24} />
                        Processing...
                      </span>
                    ) : (
                      editingProduct ? 'Update Product' : 'Add Product'
                    )}
                  </button>
                  <button
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-full transition-all duration-300"
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setEditingProduct(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>

              {editingProduct && (
                <div className="text-right mt-auto">
                  <button
                    className="text-xs bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full transition-all duration-300"
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete SKU Root
                  </button>
                </div>
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ProductsSettings;