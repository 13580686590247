import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import chillBottleImage from '../assets/images/chillBottle-trans.png';
import quotes from '../quotes.json';

const Home = () => {
  const [summary, setSummary] = useState(null);
  const [error, setError] = useState(null);
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/order-summary-24h', {
          headers: { Authorization: localStorage.getItem('authToken') }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order summary');
        }

        const data = await response.json();
        setSummary(data);
        setQuote(getRandomQuote(data.revenue));
      } catch (error) {
        console.error('Error fetching order summary:', error);
        setError('Failed to fetch order summary. Please try again later.');
      }
    };

    fetchSummary();
  }, []);

  const getRandomQuote = (revenue) => {
    let category;
    if (revenue < 800) {
      category = 'business_bad';
    } else if (revenue < 1200) {
      category = 'business_ok';
    } else {
      category = 'business_good';
    }
    const categoryQuotes = quotes[category];
    return categoryQuotes[Math.floor(Math.random() * categoryQuotes.length)];
  };

  return (
    <Layout>
      <div className="flex items-center justify-center h-screen bg-white overflow-hidden">
        <div className="text-center max-w-2xl px-4">
          <h1 className="text-4xl md:text-6xl font-bold text-purple-600 mb-6">Howdy Silicone Boys</h1>
          {error ? (
            <p className="text-red-500">{error}</p>
          ) : summary ? (
            <>
              <div className="text-xl md:text-2xl mb-6">
                <p className="mb-2">In the last 24 hours:</p>
                <p>{summary.orderCount} orders, {summary.productCount} products sold, ${summary.revenue.toFixed(2)} revenue 👈 {summary.revenue < 800 ? <><span style={{ color: 'red' }}>B</span><span style={{ color: 'green' }}>a</span><span style={{ color: 'orange' }}>d</span></> : summary.revenue < 1200 ? <><span style={{ color: 'green' }}>O</span><span style={{ color: 'blue' }}>K</span></> : <><span style={{ color: 'purple' }}>G</span><span style={{ color: 'pink' }}>o</span><span style={{ color: 'cyan' }}>o</span><span style={{ color: 'magenta' }}>d</span></>}</p>
              </div>
              <div className="flex flex-col items-center">
                <img src={chillBottleImage} alt="Chill Bottle" className="w-48 h-48 object-contain mb-4" />
                <p className="text-lg md:text-xl italic max-w-md">&quot;{quote}&quot;</p>
              </div>
            </>
          ) : (
            <p>Loading summary...</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
