import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Remove the import and just define the path
const logoPath = '/SiliCreateLogo.png';  // This will look for the image in the public folder

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    logoContainer: {
        width: 220,
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    headerRight: {
        alignItems: 'flex-end',
    },
    title: {
        fontSize: 20,
        marginBottom: 8,
        fontWeight: 'bold',
    },
    invoiceDetails: {
        fontSize: 8,
        color: '#444',
    },
    orderInfo: {
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#e5e5e5',
        padding: 10,
        borderRadius: 5,
        fontSize: 9,
    },
    infoRow: {
        flexDirection: 'row',
        marginBottom: 3,
    },
    label: {
        width: 60,
        color: '#666',
        fontSize: 9,
    },
    value: {
        flex: 1,
        fontSize: 9,
        paddingRight: 8,
    },
    table: {
        display: 'table',
        width: '100%',
        marginBottom: 30,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e5e5e5',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f8f9fa',
        borderBottomColor: '#e5e5e5',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 28,
        fontWeight: 'bold',
        color: '#666',
        fontSize: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomColor: '#e5e5e5',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 28,
        color: '#444',
        fontSize: 9,
    },
    description: {
        width: '60%',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
    },
    quantity: {
        width: '20%',
        textAlign: 'center',
    },
    price: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    totalsContainer: {
        alignItems: 'flex-end',
        marginBottom: 40,
    },
    totalRow: {
        flexDirection: 'row',
        marginBottom: 3,
    },
    totalLabel: {
        width: 100,
        textAlign: 'right',
        marginRight: 10,
        color: '#666',
        fontSize: 10,
    },
    totalValue: {
        width: 100,
        textAlign: 'right',
        fontSize: 10,
    },
    grandTotal: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 4,
        paddingTop: 4,
        borderTopWidth: 1,
        borderTopColor: '#000',
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'center',
        color: '#666',
        fontSize: 10,
        borderTopWidth: 1,
        borderTopColor: '#e5e5e5',
        paddingTop: 10,
    },
    quoteDisclaimer: {
        marginTop: 20,
        padding: 10,
        borderTop: 1,
        borderColor: '#e5e5e5',
    },
    disclaimerText: {
        fontSize: 9,
        color: '#666',
        textAlign: 'center',
        fontStyle: 'italic',
    },
});

const InvoicePDF = ({ invoiceData }) => {
    // Calculate GST by working backwards from the total price including shipping
    const calculatePriceExGST = (priceIncGST) => {
        return priceIncGST / 1.1; // Since GST is 10%, divide by 1.1 to get ex-GST amount
    };

    // Calculate totals
    const subtotalIncGST = invoiceData.items.reduce((sum, item) => sum + (item.quantity * item.price), 0);
    const shippingCost = invoiceData.postage || 0;
    const totalIncGST = subtotalIncGST + shippingCost;
    
    // Calculate GST components
    const subtotalExGST = calculatePriceExGST(subtotalIncGST);
    const shippingExGST = calculatePriceExGST(shippingCost);
    const totalExGST = subtotalExGST + shippingExGST;
    const totalGST = totalIncGST - totalExGST;

    // Format billing details
    const formatBillingDetails = () => {
        const lines = [];
        
        // Add business name if it exists
        if (invoiceData.businessName?.trim()) {
            lines.push(invoiceData.businessName);
        }
        
        // Add customer name if it exists
        if (invoiceData.customerName?.trim()) {
            lines.push(invoiceData.customerName);
        }
        
        // If neither exists, add a placeholder (shouldn't happen in practice)
        if (lines.length === 0) {
            lines.push('[No Name Provided]');
        }

        // Add email if it exists
        if (invoiceData.email?.trim()) {
            lines.push(invoiceData.email);
        }

        // Add address if it exists
        if (invoiceData.address) {
            if (invoiceData.address.street1) lines.push(invoiceData.address.street1);
            if (invoiceData.address.street2) lines.push(invoiceData.address.street2);
            if (invoiceData.address.suburb || invoiceData.address.state || invoiceData.address.postalCode) {
                const cityLine = [
                    invoiceData.address.suburb,
                    invoiceData.address.state,
                    invoiceData.address.postalCode
                ].filter(Boolean).join(' ');
                if (cityLine) lines.push(cityLine);
            }
        }

        return lines;
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src={logoPath} />
                    </View>
                    <View style={styles.headerRight}>
                        <Text style={styles.title}>
                            {invoiceData.isQuote ? 'Quote' : 'Tax Invoice'}
                        </Text>
                        <Text style={styles.invoiceDetails}>
                            {invoiceData.isQuote ? 'Quote #: ' : 'Invoice #: '}
                            {invoiceData.orderId}
                        </Text>
                        <Text style={styles.invoiceDetails}>Date: {invoiceData.orderDate}</Text>
                        {invoiceData.isQuote && invoiceData.validUntil && (
                            <Text style={styles.invoiceDetails}>
                                Valid Until: {new Date(invoiceData.validUntil).toLocaleDateString()}
                            </Text>
                        )}
                    </View>
                </View>

                <View style={styles.orderInfo}>
                    <View style={styles.infoRow}>
                        <Text style={styles.label}>Bill To:</Text>
                        <View style={styles.value}>
                            {formatBillingDetails().map((line, i) => (
                                <Text key={i}>{line}</Text>
                            ))}
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.description}>Description</Text>
                        <Text style={styles.quantity}>Qty</Text>
                        <Text style={styles.price}>Price</Text>
                    </View>

                    {invoiceData.items.map((item, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.description}>{item.description}</Text>
                            <Text style={styles.quantity}>{item.quantity}</Text>
                            <Text style={styles.price}>${item.price.toFixed(2)}</Text>
                        </View>
                    ))}
                </View>

                <View style={styles.totalsContainer}>
                    <View style={styles.totalRow}>
                        <Text style={styles.totalLabel}>Subtotal:</Text>
                        <Text style={styles.totalValue}>${subtotalExGST.toFixed(2)}</Text>
                    </View>
                    <View style={styles.totalRow}>
                        <Text style={styles.totalLabel}>Shipping:</Text>
                        <Text style={styles.totalValue}>${shippingExGST.toFixed(2)}</Text>
                    </View>
                    <View style={styles.totalRow}>
                        <Text style={styles.totalLabel}>GST (10%):</Text>
                        <Text style={styles.totalValue}>${totalGST.toFixed(2)}</Text>
                    </View>
                    <View style={[styles.totalRow, styles.grandTotal]}>
                        <Text style={styles.totalLabel}>Total:</Text>
                        <Text style={styles.totalValue}>${totalIncGST.toFixed(2)}</Text>
                    </View>
                </View>

                {invoiceData.isQuote && (
                    <View style={styles.quoteDisclaimer}>
                        <Text style={styles.disclaimerText}>
                            This is a quote only and not a tax invoice. All prices include GST.
                        </Text>
                    </View>
                )}

                <Text style={styles.footer}>
                    SiliCreate PTY LTD | ABN 81 641 183 164 | 9 Pacific Place, Kilsyth, 3137
                </Text>
            </Page>
        </Document>
    );
};

export default InvoicePDF;
