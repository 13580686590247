import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from '../components/InvoicePDF';

function DirectOrders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [newOrder, setNewOrder] = useState({
        customerName: '',
        businessName: '',
        email: '',
        phone: '',
        address: {
            street1: '',
            street2: '',
            suburb: '',
            state: '',
            postalCode: ''
        },
        items: [{ 
            sku: '', 
            description: '', 
            size: 'custom',  // or selected from product sizes
            quantity: 1, 
            price: 0,
            customSize: ''  // for custom size input
        }],
        status: 'created',
        postage: 0,
        isQuote: false,
        validUntil: ''
    });
    const [availableSkus, setAvailableSkus] = useState([]);
    const [nextOrderNumber, setNextOrderNumber] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [viewInvoiceModal, setViewInvoiceModal] = useState(false);

    useEffect(() => {
        fetchOrders();
        fetchSkus();
        if (showPreview) {
            fetchNextOrderNumber();
        }
    }, [showPreview]);

    const fetchOrders = async () => {
        try {
            const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/direct-orders', {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSkus = async () => {
        try {
            const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/get-products', {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            const data = await response.json();
            setAvailableSkus(data);
        } catch (error) {
            console.error('Error fetching SKUs:', error);
        }
    };

    const fetchNextOrderNumber = async () => {
        try {
            const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/direct-orders/next-number', {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            const data = await response.json();
            setNextOrderNumber(data.nextOrderNumber);
        } catch (error) {
            console.error('Error fetching next order number:', error);
        }
    };

    const handleAddItem = () => {
        setNewOrder(prev => ({
            ...prev,
            items: [...prev.items, { sku: '', description: '', quantity: 1, price: 0 }]
        }));
    };

    const handleItemChange = (index, field, value) => {
        setNewOrder(prev => ({
            ...prev,
            items: prev.items.map((item, i) => {
                if (i === index) {
                    if (field === 'sku') {
                        const product = availableSkus.find(p => p.sku_root === value);
                        const skusArray = typeof product?.all_skus === 'string' 
                            ? product.all_skus.split(',') 
                            : (Array.isArray(product?.all_skus) ? product.all_skus : []);
                        
                        const sizes = skusArray.map(sku => {
                            const sizeMatch = sku.trim().match(/-(\d+(?:\.\d+)?(?:kg|g)?)$/);
                            return sizeMatch ? sizeMatch[1] : null;
                        }).filter(Boolean);
                        
                        return {
                            ...item,
                            sku: value,
                            description: product ? product.product_name : item.description,
                            availableSizes: sizes,
                            size: sizes.length > 0 ? sizes[0] : 'custom'
                        };
                    }
                    if (field === 'quantity') {
                        const qty = parseInt(value) || 0;
                        return { ...item, quantity: Math.max(0, qty) };
                    }
                    if (field === 'price') {
                        const price = parseFloat(value) || 0;
                        return { ...item, price: Math.max(0, price) };
                    }
                    return { ...item, [field]: value };
                }
                return item;
            })
        }));
    };

    const handleAddressChange = (field, value) => {
        setNewOrder(prev => ({
            ...prev,
            address: {
                ...prev.address,
                [field]: value
            }
        }));
    };

    const handleCreateOrder = async () => {
        try {
            const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/direct-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authToken')
                },
                body: JSON.stringify({
                    ...newOrder,
                    isQuote: newOrder.status === 'quote',
                })
            });
            
            if (!response.ok) throw new Error('Failed to create order');
            
            const result = await response.json();
            setShowModal(false);
            fetchOrders();
            return result.orderId;
        } catch (error) {
            console.error('Error creating order:', error);
        }
    };

    const handleUpdateStatus = async (orderId, newStatus) => {
        try {
            await fetch(`https://silicreate-web-giver.silicreate.workers.dev/direct-order/${orderId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authToken')
                },
                body: JSON.stringify({ status: newStatus })
            });
            fetchOrders();
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const handlePostageChange = (value) => {
        const postage = parseFloat(value) || 0;
        setNewOrder(prev => ({
            ...prev,
            postage: Math.max(0, postage)
        }));
    };

    const fetchOrderDetails = async (orderId, status) => {
        try {
            const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/direct-orders/${orderId}`, {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            
            if (!response.ok) {
                throw new Error('Order not found');
            }
            
            const orderDetails = await response.json();

            setSelectedOrder({
                ...orderDetails,
                orderId: orderId,
                orderDate: new Date(orderDetails.orderDate).toLocaleDateString(),
                isQuote: status === 'quote'
            });
            setViewInvoiceModal(true);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const handleQuoteToggle = (isQuote) => {
        setNewOrder(prev => ({
            ...prev,
            isQuote: isQuote,
            status: isQuote ? 'quote' : 'created',
            validUntil: isQuote ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] : ''
        }));
    };

    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-purple-600">Direct Orders</h1>
                    <button
                        onClick={() => setShowModal(true)}
                        className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                    >
                        Create New Order
                    </button>
                </div>

                {loading ? (
                    <p>Loading orders...</p>
                ) : (
                    <div className="bg-white shadow-md rounded-lg overflow-x-auto">
                        <table className="min-w-full">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {orders.map((order) => (
                                    <tr key={order.order_id}>
                                        <td className="px-6 py-4 whitespace-nowrap">{order.order_id}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Date(order.order_date).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {`${order.customer_first_name} ${order.customer_last_name}`}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            ${order.order_total.toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <select
                                                value={order.order_status}
                                                onChange={(e) => handleUpdateStatus(order.order_id, e.target.value)}
                                                className={`rounded border-gray-300 ${
                                                    order.order_status === 'quote' ? 'text-purple-600' : ''
                                                }`}
                                            >
                                                <option value="quote">Quote</option>
                                                <option value="created">Created</option>
                                                <option value="paid">Paid</option>
                                                <option value="shipped">Shipped</option>
                                            </select>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button
                                                onClick={() => fetchOrderDetails(order.order_id, order.order_status)}
                                                className="text-purple-600 hover:text-purple-900"
                                            >
                                                View {order.order_status === 'quote' ? 'Quote' : 'Invoice'}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Create Order Modal */}
                {showModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                        <div className="relative top-20 mx-auto p-5 border w-[800px] shadow-lg rounded-md bg-white">
                            <div className="mt-3">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        Create New {newOrder.isQuote ? 'Quote' : 'Order'}
                                    </h3>
                                    <div className="flex items-center space-x-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                checked={!newOrder.isQuote}
                                                onChange={() => handleQuoteToggle(false)}
                                            />
                                            <span className="ml-2">Invoice</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                checked={newOrder.isQuote}
                                                onChange={() => handleQuoteToggle(true)}
                                            />
                                            <span className="ml-2">Quote</span>
                                        </label>
                                    </div>
                                </div>

                                {newOrder.isQuote && (
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Valid Until</label>
                                        <input
                                            type="date"
                                            value={newOrder.validUntil}
                                            onChange={(e) => setNewOrder(prev => ({
                                                ...prev,
                                                validUntil: e.target.value
                                            }))}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                            min={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                )}

                                <div className="space-y-4">
                                    {/* Customer Information Section */}
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Customer Name</label>
                                            <input
                                                type="text"
                                                value={newOrder.customerName}
                                                onChange={(e) => setNewOrder(prev => ({ ...prev, customerName: e.target.value }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Business Name</label>
                                            <input
                                                type="text"
                                                value={newOrder.businessName}
                                                onChange={(e) => setNewOrder(prev => ({ ...prev, businessName: e.target.value }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Email</label>
                                            <input
                                                type="email"
                                                value={newOrder.email}
                                                onChange={(e) => setNewOrder(prev => ({ ...prev, email: e.target.value }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">Phone</label>
                                            <input
                                                type="tel"
                                                value={newOrder.phone}
                                                onChange={(e) => setNewOrder(prev => ({ ...prev, phone: e.target.value }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                            />
                                        </div>
                                    </div>

                                    {/* Address Section */}
                                    <div className="border-t pt-4">
                                        <h4 className="text-md font-medium mb-2">Address</h4>
                                        <div className="grid grid-cols-1 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Street Address</label>
                                                <input
                                                    type="text"
                                                    value={newOrder.address.street1}
                                                    onChange={(e) => handleAddressChange('street1', e.target.value)}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                    placeholder="Street address"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={newOrder.address.street2}
                                                    onChange={(e) => handleAddressChange('street2', e.target.value)}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                    placeholder="Apartment, suite, etc. (optional)"
                                                />
                                            </div>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={newOrder.address.suburb}
                                                        onChange={(e) => handleAddressChange('suburb', e.target.value)}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                        placeholder="Suburb"
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={newOrder.address.state}
                                                        onChange={(e) => handleAddressChange('state', e.target.value)}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                        placeholder="State"
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={newOrder.address.postalCode}
                                                        onChange={(e) => handleAddressChange('postalCode', e.target.value)}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                        placeholder="Postal Code"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Order Items Section */}
                                    <div className="border-t pt-4">
                                        <h4 className="text-md font-medium mb-2">Order Items</h4>
                                        {newOrder.items.map((item, index) => (
                                            <div key={index} className="space-y-2 mb-4 p-4 border rounded">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">Product</label>
                                                        <select
                                                            value={item.sku}
                                                            onChange={(e) => handleItemChange(index, 'sku', e.target.value)}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                        >
                                                            <option value="">Select Product</option>
                                                            {availableSkus.map(sku => (
                                                                <option key={sku.sku_root} value={sku.sku_root}>
                                                                    {sku.product_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {item.sku && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700">Size</label>
                                                            <select
                                                                value={item.size}
                                                                onChange={(e) => handleItemChange(index, 'size', e.target.value)}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                            >
                                                                {item.availableSizes?.map(size => (
                                                                    <option key={size} value={size}>{size}</option>
                                                                ))}
                                                                <option value="custom">Custom Size</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {item.size === 'custom' && (
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700">Custom Size</label>
                                                            <input
                                                                type="text"
                                                                value={item.customSize}
                                                                onChange={(e) => handleItemChange(index, 'customSize', e.target.value)}
                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                                placeholder="e.g., 2.5kg"
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="grid grid-cols-2 gap-4 mt-2">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">Quantity</label>
                                                        <input
                                                            type="number"
                                                            value={item.quantity || 0}
                                                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                            min="0"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">Price (per unit)</label>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <span className="text-gray-500 sm:text-sm">$</span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                value={item.price || 0}
                                                                onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                                                className="block w-full pl-7 rounded-md border-gray-300 shadow-sm"
                                                                step="0.01"
                                                                min="0"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <button
                                            onClick={handleAddItem}
                                            className="w-full bg-gray-100 text-gray-700 px-4 py-2 rounded hover:bg-gray-200"
                                        >
                                            Add Another Item
                                        </button>
                                    </div>

                                    {/* Postage Section */}
                                    <div className="border-t pt-4">
                                        <label className="block text-sm font-medium text-gray-700">Postage</label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <span className="text-gray-500 sm:text-sm">$</span>
                                            </div>
                                            <input
                                                type="number"
                                                value={newOrder.postage || 0}
                                                onChange={(e) => handlePostageChange(e.target.value)}
                                                className="block w-full pl-7 rounded-md border-gray-300 shadow-sm"
                                                step="0.01"
                                                min="0"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            onClick={() => setShowPreview(true)}
                                            className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                                        >
                                            Preview Invoice
                                        </button>
                                        <button
                                            onClick={() => setShowModal(false)}
                                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Preview Modal */}
                {showPreview && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                        <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
                            <div className="flex justify-end mb-4">
                                <button
                                    onClick={async () => {
                                        const orderId = await handleCreateOrder();
                                        if (orderId) {
                                            setShowPreview(false);
                                        }
                                    }}
                                    className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 mr-2"
                                >
                                    Save {newOrder.status === 'quote' ? 'Quote' : 'Order'}
                                </button>
                                <button
                                    onClick={() => setShowPreview(false)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                                >
                                    Close
                                </button>
                            </div>
                            <PDFViewer width="100%" height="600">
                                <InvoicePDF
                                    invoiceData={{
                                        ...newOrder,
                                        orderId: nextOrderNumber || 'Loading...',
                                        orderDate: new Date().toLocaleDateString(),
                                        isQuote: newOrder.status === 'quote'
                                    }}
                                />
                            </PDFViewer>
                        </div>
                    </div>
                )}

                {/* View Invoice/Quote Modal */}
                {viewInvoiceModal && selectedOrder && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                        <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
                            <div className="flex justify-end mb-4">
                                <button
                                    onClick={() => setViewInvoiceModal(false)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                                >
                                    Close
                                </button>
                            </div>
                            <PDFViewer width="100%" height="600">
                                <InvoicePDF invoiceData={selectedOrder} />
                            </PDFViewer>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default DirectOrders;
