import React from 'react';
import Modal from 'react-modal';

const EditPackagingModal = ({
    isOpen,
    onClose,
    editingPackaging,
    setEditingPackaging,
    handleSaveEditedPackaging
}) => {
    const editModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '400px',
            maxHeight: '90vh',
            overflow: 'auto',
            borderRadius: '8px',
            padding: '20px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };

    if (!editingPackaging) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={editModalStyles}
            contentLabel="Edit Shipment Packaging"
        >
            <div className="relative">
                <h2 className="text-2xl font-bold mb-4">Edit Shipment Packaging</h2>
                <button 
                    onClick={onClose}
                    className="absolute top-0 right-0 text-gray-600 hover:text-gray-800"
                >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            value={editingPackaging.name}
                            onChange={(e) => setEditingPackaging({...editingPackaging, name: e.target.value})}
                            className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="e.g., Small Box"
                        />
                        <p className="mt-1 text-sm text-gray-500">Enter a descriptive name for this packaging option.</p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Length (cm)</label>
                        <input
                            type="number"
                            value={editingPackaging.length}
                            onChange={(e) => setEditingPackaging({...editingPackaging, length: e.target.value})}
                            className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="e.g., 30"
                        />
                        <p className="mt-1 text-sm text-gray-500">Enter the length of the packaging in centimeters.</p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Width (cm)</label>
                        <input
                            type="number"
                            value={editingPackaging.width}
                            onChange={(e) => setEditingPackaging({...editingPackaging, width: e.target.value})}
                            className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="e.g., 20"
                        />
                        <p className="mt-1 text-sm text-gray-500">Enter the width of the packaging in centimeters.</p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Height (cm)</label>
                        <input
                            type="number"
                            value={editingPackaging.height}
                            onChange={(e) => setEditingPackaging({...editingPackaging, height: e.target.value})}
                            className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="e.g., 15"
                        />
                        <p className="mt-1 text-sm text-gray-500">Enter the height of the packaging in centimeters.</p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Max Weight (kg)</label>
                        <input
                            type="number"
                            value={editingPackaging.max_weight}
                            onChange={(e) => setEditingPackaging({...editingPackaging, max_weight: e.target.value})}
                            className="mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="e.g., 5"
                        />
                        <p className="mt-1 text-sm text-gray-500">Enter the maximum weight this packaging can hold in kilograms.</p>
                    </div>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                    <button 
                        onClick={handleSaveEditedPackaging}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Save
                    </button>
                    <button 
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditPackagingModal;