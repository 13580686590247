import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';

function OrderHistory() {
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/orders?page=${page}`, {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch orders');
            }
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setPage(prevPage => Math.max(0, prevPage - 1));
    };

    const formatTimeAgo = (date) => {
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);
        // Remove the unused 'interval' variable
        if (seconds >= 31536000) return `${Math.floor(seconds / 31536000)} year${Math.floor(seconds / 31536000) > 1 ? 's' : ''} ago`;
        if (seconds >= 2592000) return `${Math.floor(seconds / 2592000)} month${Math.floor(seconds / 2592000) > 1 ? 's' : ''} ago`;
        if (seconds >= 86400) return `${Math.floor(seconds / 86400)} day${Math.floor(seconds / 86400) > 1 ? 's' : ''} ago`;
        if (seconds >= 3600) return `${Math.floor(seconds / 3600)} hour${Math.floor(seconds / 3600) > 1 ? 's' : ''} ago`;
        if (seconds >= 60) return `${Math.floor(seconds / 60)} minute${Math.floor(seconds / 60) > 1 ? 's' : ''} ago`;
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    };

    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">
                    <span className="font-serif text-red-500">O</span>
                    <span className="font-sans text-blue-500">r</span>
                    <span className="font-mono text-green-500">d</span>
                    <span className="font-cursive text-yellow-500">e</span>
                    <span className="font-fantasy text-purple-500">r</span>
                    <span className="font-sans-serif text-pink-500"> </span>
                    <span className="font-display text-orange-500">H</span>
                    <span className="font-handwriting text-teal-500">i</span>
                    <span className="font-comic text-indigo-500">s</span>
                    <span className="font-decorative text-lime-500">t</span>
                    <span className="font-blackletter text-cyan-500">o</span>
                    <span className="font-script text-fuchsia-500">r</span>
                    <span className="font-slab-serif text-amber-500">y</span>
                </h1>
                {loading ? (
                    <p className="text-center">Loading...</p>
                ) : (
                    <>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Platform</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Date</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Total</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fulfillment Channel</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {orders.map(order => (
                                        <tr key={`${order.order_id}-${order.platform}`}>
                                            <td className="px-6 py-4 whitespace-nowrap">{order.order_id}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{order.platform}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{formatTimeAgo(new Date(order.order_date))}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">${order.order_total.toFixed(2)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {['Pending', 'NOT_STARTED', 'PENDING'].includes(order.order_status) ? 'Pending' : 
                                                ['FULFILLED', 'Shipped'].includes(order.order_status) ? 'Shipped' : order.order_status}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">{order.fulfillment_channel}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-6 flex justify-between">
                            <button
                                onClick={handlePreviousPage}
                                disabled={page === 0}
                                className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50"
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={orders.length < 20}
                                className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50"
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
}

export default OrderHistory;