import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from '../components/InvoicePDF';

function Invoicer() {
    const [orderId, setOrderId] = useState('');
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [editableData, setEditableData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [pdfReady, setPdfReady] = useState(false);

    useEffect(() => {
        if (orderData) {
            setEditableData(getInitialEditableData(orderData));
            setIsEditing(true);
            setPdfReady(false); // Reset PDF ready state when order data changes
        }
    }, [orderData]);

    const searchOrder = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/order/${orderId}`, {
                headers: {
                    'Authorization': localStorage.getItem('authToken')
                }
            });
            
            if (!response.ok) {
                throw new Error('Order not found');
            }
            
            const data = await response.json();
            setOrderData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Helper functions to handle different order formats
    const getCustomerName = (data) => {
        if (!data.details) return 'N/A';
        if (data.details.BuyerInfo) return data.details.BuyerInfo.BuyerEmail; // Amazon
        if (data.details.buyer) return data.details.buyer.username; // eBay
        if (data.details.customerEmail) return data.details.customerEmail; // Squarespace
        return 'N/A';
    };

    const getFormattedItems = (data) => {
        if (!data.details) return [];
        let items = [];
        if (data.details.OrderItems) { // Amazon
            items = data.details.OrderItems.map(item => ({
                description: item.Title,
                quantity: parseInt(item.QuantityOrdered),
                price: parseFloat(item.ItemPrice.Amount)
            }));
        } else if (data.details.lineItems) { // eBay or Squarespace
            items = data.details.lineItems.map(item => ({
                description: item.title || item.productName,
                quantity: item.quantity,
                price: parseFloat(item.lineItemCost?.value || item.unitPricePaid?.value)
            }));
        }
        return items;
    };

    const getInitialEditableData = (orderData) => ({
        customerName: getCustomerName(orderData),
        items: getFormattedItems(orderData),
        orderId: orderData.summary.order_id,
        orderDate: new Date(orderData.summary.order_date).toLocaleDateString()
    });

    const handleEditData = () => {
        if (!editableData && orderData) {
            setEditableData(getInitialEditableData(orderData));
        }
        setIsEditing(true);
    };

    const handleUpdateField = (field, value) => {
        setEditableData(prev => ({
            ...prev,
            [field]: value
        }));
        setIsDirty(true);
        if (showPreview) {
            setShowPreview(false);
        }
    };

    const handleUpdateItem = (index, field, value) => {
        setEditableData(prev => ({
            ...prev,
            items: prev.items.map((item, i) => 
                i === index ? { ...item, [field]: value } : item
            )
        }));
        setIsDirty(true);
        if (showPreview) {
            setShowPreview(false);
        }
    };

    const handleGenerateInvoice = () => {
        setPdfReady(false); // Reset first
        setTimeout(() => {
            setShowPreview(true);
            setIsDirty(false);
            setPdfReady(true);
        }, 100); // Small delay to ensure state updates are processed
    };

    // Remove or comment out the handleDownloadPDF and handleDownloadPNG functions for now
    // since they're causing issues and we're not using them

    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">
                    <span className="text-purple-600">Invoice Generator</span>
                </h1>
                
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700">Order ID</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                            type="text"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md border border-gray-300"
                            placeholder="Enter order ID"
                        />
                        <button
                            onClick={searchOrder}
                            disabled={loading}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-purple-600 hover:bg-purple-700 disabled:opacity-50"
                        >
                            {loading ? 'Searching...' : 'Search'}
                        </button>
                    </div>
                    {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                </div>

                {orderData && (
                    <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                        {!orderData.details && (
                            <div className="mb-4 p-2 bg-yellow-100 text-yellow-700 rounded">
                                Warning: Detailed order data unavailable
                            </div>
                        )}
                        <h2 className="text-lg font-semibold mb-2">Order Details</h2>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <p className="text-sm text-gray-600">Platform</p>
                                <p className="font-medium">{orderData.summary.platform}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Customer</p>
                                <p className="font-medium">{getCustomerName(orderData)}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Order Date</p>
                                <p className="font-medium">
                                    {new Date(orderData.summary.order_date).toLocaleDateString()}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Total</p>
                                <p className="font-medium">${orderData.summary.order_total.toFixed(2)}</p>
                            </div>
                        </div>
                        
                        <div className="mt-6">
                            <h3 className="text-lg font-medium mb-4">Edit Invoice Details</h3>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Bill To</label>
                                    <textarea
                                        value={editableData?.customerName || ''}
                                        onChange={(e) => handleUpdateField('customerName', e.target.value)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                        rows={4}  // Show 4 rows by default
                                        placeholder="Enter billing details"
                                    />
                                </div>

                                <div>
                                    <h4 className="font-medium mb-2">Items</h4>
                                    {editableData?.items.map((item, index) => (
                                        <div key={index} className="grid grid-cols-3 gap-2 mb-2">
                                            <input
                                                type="text"
                                                value={item.description}
                                                onChange={(e) => handleUpdateItem(index, 'description', e.target.value)}
                                                className="rounded-md border-gray-300 shadow-sm"
                                                placeholder="Description"
                                            />
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => handleUpdateItem(index, 'quantity', parseInt(e.target.value))}
                                                className="rounded-md border-gray-300 shadow-sm"
                                                placeholder="Quantity"
                                            />
                                            <input
                                                type="number"
                                                value={item.price}
                                                onChange={(e) => handleUpdateItem(index, 'price', parseFloat(e.target.value))}
                                                className="rounded-md border-gray-300 shadow-sm"
                                                placeholder="Price"
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div className="pt-4">
                                    <button
                                        onClick={handleGenerateInvoice}
                                        className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                                    >
                                        {isDirty ? 'Generate Updated Invoice' : 'Generate Invoice'}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {showPreview && (
                            <div className="mt-4 border rounded-lg p-4">
                                {!pdfReady && (
                                    <div className="text-center py-4">
                                        <p>Preparing PDF preview...</p>
                                    </div>
                                )}
                                {pdfReady && editableData && (
                                    <PDFViewer width="100%" height={1200}>
                                        <InvoicePDF invoiceData={editableData} />
                                    </PDFViewer>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Invoicer;
