// sim@home.js
import React, { useState } from 'react';
import chillBottle from '../assets/images/chillBottle.webp';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    const result = await response.json();

    if (response.ok) {
      localStorage.setItem('authToken', result.token); // Store the token for future requests
      // Redirect or proceed to the next step after login
      window.location.href = '/';
    } else {
      setError(result.message);
    }
  };
    return (
      <div className="flex bg-purple-200 items-center justify-center min-h-screen">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
            <img src={chillBottle} alt="Dancing Bottle" className="w-64 h-auto mb-4 mx-auto rounded-full" />
            <h1 className="text-2xl font-semibold text-center mb-6">SiliHUB</h1>
            <form onSubmit={handleLogin} className="space-y-4">
            <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
                required
                />
            </div>
            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
                required
                />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
            >
                Login
            </button>
            </form>
      </div>
    </div>
    );
};

export default Login;
