import React, { useState, useEffect } from 'react';
import ProductsSettings from '../components/ProductsSettings';
import PackagingSettings from '../components/PackagingSettings';
import Layout from '../components/Layout';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('Products');
  const [isLoading, setIsLoading] = useState(false);

  const tabs = ['Products', 'Packaging'];

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); // Simulate a short loading time

    return () => clearTimeout(timer);
  }, [activeTab]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      );
    }

    switch (activeTab) {
      case 'Products':
        return <ProductsSettings />;
      case 'Packaging':
        return <PackagingSettings />;
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <Layout>
      <div className="flex h-full bg-white rounded-3xl overflow-hidden">
        {/* Left sidebar */}
        <div className="w-72 bg-gradient-to-b from-purple-100 to-white p-6 rounded-r-3xl">
          <ul className="space-y-4">
            {tabs.map((tab) => (
              <li key={tab}>
                <button
                  className={`w-full text-left px-6 py-3 rounded-full transition-all duration-300 ${
                    activeTab === tab
                      ? 'bg-gradient-to-r from-purple-500 to-indigo-500 text-white transform scale-105'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() => !isLoading && setActiveTab(tab)}
                  disabled={isLoading}
                >
                  {tab}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Main content area */}
        <div className="flex-1 p-8 rounded-l-3xl">
          <h1 className="text-4xl font-bold mb-8 text-gray-800">{activeTab} Settings</h1>
          <div className="bg-white rounded-3xl border border-gray-100 min-h-[300px]">
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;