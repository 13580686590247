import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Layout from '../components/Layout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ChartTooltip, Legend);

const SellRate = () => {
  const [productData, setProductData] = useState([]);
  const [daysBack, setDaysBack] = useState(500);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedProduct, setExpandedProduct] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [fbaInventory, setFbaInventory] = useState({});
  const [extraStock, setExtraStock] = useState({});
  const [hiddenProducts, setHiddenProducts] = useState(() => {
    const saved = localStorage.getItem('hiddenSellRateProducts');
    return saved ? JSON.parse(saved) : [];
  });
  const [isUnhideModalOpen, setIsUnhideModalOpen] = useState(false);
  const [editingExtraStock, setEditingExtraStock] = useState({});
  const [savingExtraStock, setSavingExtraStock] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSkuRoot, setSelectedSkuRoot] = useState('');
  const [subSkus, setSubSkus] = useState([]);
  const [selectedSubSkus, setSelectedSubSkus] = useState([]);
  const [skuRootData, setSkuRootData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [allSubSkus, setAllSubSkus] = useState([]);

  const skuConversion = {
    '1O-YREI-EBD2': 'SC-W-15-4-FBA',
    '1Y-MR6D-7NS2': 'SC-W-15-2-FBA',
    '3J-FRX9-984B': 'SC-W-15-1-FBA'
  };

  const convertSku = (sku) => {
    return skuConversion[sku] || sku;
  };

  const calculateDaysUntilOutOfStock = useCallback((totalStock, extraStockAmount, dailyRate) => {
    const combinedStock = totalStock + (extraStockAmount || 0);
    console.log(`Calculating for: Total Stock: ${totalStock}, Extra Stock: ${extraStockAmount}, Daily Rate: ${dailyRate}`);
    
    // If there's no daily rate, return Infinity
    if (dailyRate <= 0) {
      console.log(`Result: Infinity days (no daily rate)`);
      return Infinity;
    }

    // Calculate days based on combined stock
    const result = Math.floor(combinedStock / dailyRate);
    console.log(`Result: ${result} days`);
    return result;
  }, []);

  const productDataWithExtraStock = useMemo(() => {
    return productData.map(product => {
      const extraStockAmount = parseFloat(extraStock[product.sku_root]) || 0;
      console.log(`Processing ${product.sku_root}:`);
      console.log(`  Total Stock: ${product.total_stock}`);
      console.log(`  Extra Stock: ${extraStockAmount}`);
      console.log(`  Daily Rate: ${product.daily_rate}`);
      
      const daysUntilOutOfStock = calculateDaysUntilOutOfStock(
        product.total_stock,
        extraStockAmount,
        product.daily_rate
      );

      return {
        ...product,
        extra_stock: extraStockAmount,
        days_until_out_of_stock: daysUntilOutOfStock
      };
    });
  }, [productData, extraStock, calculateDaysUntilOutOfStock]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        const [productDataResponse, extraStockResponse] = await Promise.all([
          fetchProductData(),
          fetchExtraStock()
        ]);
        console.log('Product Data:', productDataResponse);
        console.log('Extra Stock:', extraStockResponse);
        setProductData(productDataResponse);
        setExtraStock(extraStockResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [daysBack]);

  useEffect(() => {
    localStorage.setItem('hiddenSellRateProducts', JSON.stringify(hiddenProducts));
  }, [hiddenProducts]);

  useEffect(() => {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - daysBack);
    setEndDate(end.toISOString().split('T')[0]);
    setStartDate(start.toISOString().split('T')[0]);
  }, [daysBack]);

  const fetchProductData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [skuRoots, warehouseStock, fbaInventory] = await Promise.all([
        fetchSkuRoots(),
        fetchWarehouseStock(),
        fetchFBAInventory(), 
        fetchExtraStock()
      ]);

      if (!Array.isArray(skuRoots) || skuRoots.length === 0) {
        throw new Error('No SKU roots found');
      }

      const sellRates = await Promise.all(skuRoots.map(sku => fetchSellRateData(sku.sku_root)));

      const combinedData = skuRoots.map((sku, index) => {
        const stockInfo = warehouseStock.find(item => item.group_key === sku.sku_root);
        const sellRate = sellRates[index];
        const fbaStockDetails = calculateFBAStockDetails(sku.sku_root, fbaInventory);
        const warehouseStockDetails = getWarehouseStockDetails(stockInfo);
        const totalStock = calculateTotalStock(warehouseStockDetails, fbaStockDetails);
        const daysUntilOutOfStock = calculateDaysUntilOutOfStock(totalStock, 0, sellRate.averageDaily);
        
        return {
          sku_root: sku.sku_root,
          product_name: sku.product_name,
          daily_rate: sellRate.averageDaily,
          daily_rate_fba: sellRate.averageDailyFBA,
          daily_rate_non_fba: sellRate.averageDailyNonFBA,
          total_stock: totalStock,
          warehouse_stock: warehouseStockDetails,
          fba_stock: fbaStockDetails,
          days_until_out_of_stock: daysUntilOutOfStock,
          sell_rate_data: sellRate.data
        };
      });

      return combinedData;
    } catch (err) {
      console.error('Error fetching product data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFBAInventory = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/fba-inventory', {
        headers: {
          'Authorization': localStorage.getItem('authToken'),
        },
      });
      if (!response.ok) throw new Error('Failed to fetch FBA inventory');
      const data = await response.json();
      console.log('FBA Inventory data:', data); // Log the data for debugging
      return data;
    } catch (err) {
      console.error('Error fetching FBA inventory:', err);
      return null; // Return null instead of throwing an error
    }
  };

  const calculateFBAStockDetails = (skuRoot, fbaInventory) => {
    if (!fbaInventory || !fbaInventory.inventorySummaries) {
      console.warn(`No FBA inventory data for SKU root: ${skuRoot}`);
      return { total: 0, details: [] };
    }
    const details = fbaInventory.inventorySummaries
      .filter(item => {
        const convertedSku = convertSku(item.sellerSku);
        // Use a more precise matching condition
        return convertedSku.startsWith(skuRoot + '-') || convertedSku === skuRoot;
      })
      .map(item => {
        const convertedSku = convertSku(item.sellerSku);
        const kilograms = getKilogramsFromSku(convertedSku);
        return {
          sku: convertedSku,
          quantity: item.inventoryDetails.fulfillableQuantity,
          kilograms: item.inventoryDetails.fulfillableQuantity * kilograms
        };
      });
    
    const total = details.reduce((sum, item) => sum + item.kilograms, 0);
    return { total, details };
  };

  const getWarehouseStockDetails = (stockInfo) => {
    if (!stockInfo || !stockInfo.sku_details) {
      return { total: 0, details: [] };
    }
    const details = JSON.parse(stockInfo.sku_details).map(detail => ({
      sku: detail.sku,
      quantity: detail.quantity,
      kilograms: detail.quantity * getKilogramsFromSku(detail.sku)
    }));
    const total = details.reduce((sum, item) => sum + item.kilograms, 0);
    return { total, details };
  };

  const calculateTotalStock = (warehouseStock, fbaStock) => {
    return warehouseStock.total + fbaStock.total;
  };

  const fetchSkuRoots = async () => {
    const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/sku-roots', {
      headers: {
        'Authorization': localStorage.getItem('authToken'),
      },
    });
    if (!response.ok) throw new Error('Failed to fetch SKU roots');
    return await response.json();
  };

  const fetchWarehouseStock = async () => {
    const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/warehouse-stock', {
      headers: {
        'Authorization': localStorage.getItem('authToken'),
      },
    });
    if (!response.ok) throw new Error('Failed to fetch warehouse stock');
    return await response.json();
  };

  const fetchSellRateData = async (skuRoot) => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - daysBack);
    
    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();
    
    const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/sell-rate/${skuRoot}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
      headers: {
        'Authorization': localStorage.getItem('authToken'),
      },
    });
    if (!response.ok) throw new Error('Failed to fetch sell rate data');
    const data = await response.json();
    return processSellRateData(data, startDate, endDate);
  };

  const processSellRateData = (data, startDate, endDate) => {
    if (!Array.isArray(data) || data.length === 0) {
      return { averageDaily: 0, averageDailyFBA: 0, averageDailyNonFBA: 0, data: [] };
    }

    const dailySellRate = {};
    const dailySellRateFBA = {};
    const dailySellRateNonFBA = {};
    let totalKilograms = 0;
    let totalKilogramsFBA = 0;
    let totalKilogramsNonFBA = 0;

    data.forEach(sale => {
      const date = new Date(sale.time_stamp).toISOString().split('T')[0];
      const kilograms = getKilogramsFromSku(sale.sku) * sale.total_sold;
      totalKilograms += kilograms;

      if (sale.sku.endsWith('-FBA')) {
        totalKilogramsFBA += kilograms;
        dailySellRateFBA[date] = (dailySellRateFBA[date] || 0) + kilograms;
      } else {
        totalKilogramsNonFBA += kilograms;
        dailySellRateNonFBA[date] = (dailySellRateNonFBA[date] || 0) + kilograms;
      }

      dailySellRate[date] = (dailySellRate[date] || 0) + kilograms;
    });

    const sortedDates = Object.keys(dailySellRate).sort();
    const sellRateValues = sortedDates.map(date => dailySellRate[date]);

    const dayCount = (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) + 1;
    const averageDaily = totalKilograms / dayCount;
    const averageDailyFBA = totalKilogramsFBA / dayCount;
    const averageDailyNonFBA = totalKilogramsNonFBA / dayCount;

    const rollingAverage7 = calculateRollingAverage(sellRateValues, 7);
    const rollingAverage30 = calculateRollingAverage(sellRateValues, 30);

    return { 
      averageDaily,
      averageDailyFBA,
      averageDailyNonFBA,
      data: {
        labels: sortedDates,
        datasets: [
          {
            label: 'Daily Sell Rate (kg)',
            data: sellRateValues,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: '7-Day Rolling Average (kg)',
            data: rollingAverage7,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          },
          {
            label: '30-Day Rolling Average (kg)',
            data: rollingAverage30,
            borderColor: 'rgb(54, 162, 235)',
            tension: 0.1
          }
        ]
      }
    };
  };

  const calculateRollingAverage = (data, window) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (i < window - 1) {
        result.push(null);
      } else {
        const windowSlice = data.slice(i - window + 1, i + 1);
        const average = windowSlice.reduce((sum, value) => sum + value, 0) / window;
        result.push(average);
      }
    }
    return result;
  };

  const getKilogramsFromSku = (sku) => {
    const match = sku.match(/-(\d+(?:\.\d+)?)(?:-FBA)?$/);
    if (match) {
      return parseFloat(match[1]);
    }
    // Handle cases where the weight might be in a different format
    const alternateMatch = sku.match(/(\d+(?:\.\d+)?)(?:kg|g)?(?:-FBA)?$/i);
    if (alternateMatch) {
      let weight = parseFloat(alternateMatch[1]);
      if (sku.toLowerCase().endsWith('g-fba') || sku.toLowerCase().endsWith('g')) {
        weight /= 1000; // Convert grams to kilograms
      }
      return weight;
    }
    console.warn(`Unable to extract weight from SKU: ${sku}`);
    return 0;
  };

  const toggleExpandProduct = (product) => {
    if (expandedProduct && expandedProduct.sku_root === product.sku_root) {
      setExpandedProduct(null);
    } else {
      setExpandedProduct(product);
    }
  };

  const sortData = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }

    const sortedData = [...productData].sort((a, b) => {
      let aValue = a[column];
      let bValue = b[column];

      // Special handling for days_until_out_of_stock
      if (column === 'days_until_out_of_stock') {
        aValue = calculateDaysUntilOutOfStock(a.total_stock, extraStock[a.sku_root] || 0, a.daily_rate);
        bValue = calculateDaysUntilOutOfStock(b.total_stock, extraStock[b.sku_root] || 0, b.daily_rate);
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setProductData(sortedData);
  };

  const SortableHeader = ({ column, label }) => (
    <th
      scope="col"
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
      onClick={() => sortData(column)}
    >
      {label}
      {sortColumn === column && (
        <span className="ml-1">
          {sortDirection === 'asc' ? '▲' : '▼'}
        </span>
      )}
    </th>
  );

  const fetchExtraStock = async () => {
    try {
      const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/extra-stock', {
        headers: {
          'Authorization': localStorage.getItem('authToken'),
        },
      });
      if (!response.ok) throw new Error('Failed to fetch extra stock');
      const data = await response.json();
      console.log('Fetched Extra Stock:', data);
      return data;
    } catch (err) {
      console.error('Error fetching extra stock:', err);
      return {};
    }
  };

  const handleExtraStockChange = (skuRoot, value) => {
    setEditingExtraStock(prev => ({
      ...prev,
      [skuRoot]: value
    }));
  };

  const handleExtraStockSubmit = async (skuRoot) => {
    setSavingExtraStock(prev => ({ ...prev, [skuRoot]: true }));
    try {
      await saveExtraStock(skuRoot, editingExtraStock[skuRoot]);
      setExtraStock(prev => ({
        ...prev,
        [skuRoot]: parseFloat(editingExtraStock[skuRoot]) || 0
      }));
    } catch (error) {
      console.error('Error saving extra stock:', error);
    } finally {
      setSavingExtraStock(prev => ({ ...prev, [skuRoot]: false }));
      setEditingExtraStock(prev => {
        const newState = { ...prev };
        delete newState[skuRoot];
        return newState;
      });
    }
  };

  const saveExtraStock = async (skuRoot, value) => {
    const response = await fetch('https://silicreate-web-giver.silicreate.workers.dev/extra-stock', {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('authToken'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ [skuRoot]: value }),
    });
    if (!response.ok) throw new Error('Failed to save extra stock');
  };

  const hideProduct = (skuRoot) => {
    setHiddenProducts([...hiddenProducts, skuRoot]);
  };

  const unhideProduct = (skuRoot) => {
    setHiddenProducts(hiddenProducts.filter(p => p !== skuRoot));
  };

  const openUnhideModal = () => {
    setIsUnhideModalOpen(true);
  };

  const closeUnhideModal = () => {
    setIsUnhideModalOpen(false);
  };

  const handleDaysBackChange = (newDaysBack) => {
    // Validate and set a minimum value of 1
    const validatedDays = Math.max(1, parseInt(newDaysBack) || 1);
    setDaysBack(validatedDays);
  };

  const fetchAllSubSkus = useCallback(async (skuRoot) => {
    try {
      const response = await fetch(`https://silicreate-web-giver.silicreate.workers.dev/all-sub-skus/${skuRoot}`, {
        headers: {
          'Authorization': localStorage.getItem('authToken'),
        },
      });
      if (!response.ok) throw new Error('Failed to fetch all sub-SKUs');
      const data = await response.json();
      console.log('All possible sub-SKUs:', data); // Debug log
      setAllSubSkus(data);
    } catch (err) {
      console.error('Error fetching all sub-SKUs:', err);
    }
  }, []);

  const fetchSkuRootData = useCallback(async (skuRoot) => {
    if (!startDate || !endDate) return;
    try {
      const [sellRateResponse, allSubSkusResponse] = await Promise.all([
        fetch(`https://silicreate-web-giver.silicreate.workers.dev/sell-rate/${skuRoot}?startDate=${startDate}&endDate=${endDate}`, {
          headers: {
            'Authorization': localStorage.getItem('authToken'),
          },
        }),
        fetch(`https://silicreate-web-giver.silicreate.workers.dev/all-sub-skus/${skuRoot}`, {
          headers: {
            'Authorization': localStorage.getItem('authToken'),
          },
        })
      ]);

      if (!sellRateResponse.ok) throw new Error('Failed to fetch SKU root data');
      if (!allSubSkusResponse.ok) throw new Error('Failed to fetch all sub-SKUs');

      const sellRateData = await sellRateResponse.json();
      const allSubSkusData = await allSubSkusResponse.json();

      console.log('Fetched SKU root data:', sellRateData);
      console.log('All possible sub-SKUs:', allSubSkusData);

      setSkuRootData(sellRateData);
      setAllSubSkus(allSubSkusData);
      setSubSkus(Array.from(new Set(sellRateData.map(item => item.sku))));
      setSelectedSubSkus(allSubSkusData); // Auto-select all sub-SKUs
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedSkuRoot) {
      fetchSkuRootData(selectedSkuRoot);
    }
  }, [selectedSkuRoot, fetchSkuRootData]);

  const handleSkuRootChange = (event) => {
    setSelectedSkuRoot(event.target.value);
    setSelectedSubSkus([]);
  };

  const handleSubSkuChange = (event) => {
    const sku = event.target.value;
    setSelectedSubSkus(prev => 
      prev.includes(sku) ? prev.filter(item => item !== sku) : [...prev, sku]
    );
  };

  const combineSkus = (skus) => {
    return skus.map(sku => sku.replace('-FBA', '')).filter((value, index, self) => self.indexOf(value) === index);
  };

  const calculateMovingAverage = (data, days) => {
    return data.map((_, index, array) => {
      const start = Math.max(0, index - days + 1);
      const slice = array.slice(start, index + 1);
      return slice.reduce((sum, value) => sum + value, 0) / slice.length;
    });
  };

  const chartData = useMemo(() => {
    if (!skuRootData || selectedSubSkus.length === 0) return null;

    const allDates = Array.from(new Set(skuRootData.map(item => item.time_stamp))).sort();
    const combinedSkus = combineSkus(selectedSubSkus);

    const datasets = combinedSkus.map(sku => {
      const skuData = skuRootData.filter(item => item.sku.replace('-FBA', '') === sku);
      const dailyData = allDates.map(date => {
        const dataPoints = skuData.filter(item => item.time_stamp === date);
        return dataPoints.reduce((sum, item) => sum + item.total_sold, 0);
      });
      const thirtyDayAvg = calculateMovingAverage(dailyData, 30);

      return {
        label: `${sku} - 30 Day Avg`,
        data: thirtyDayAvg,
        borderColor: `hsl(${Math.random() * 360}, 70%, 50%)`,
        tension: 0.1
      };
    });

    return {
      labels: allDates.map(date => new Date(date).toLocaleDateString()),
      datasets
    };
  }, [skuRootData, selectedSubSkus]);

  // Update the input field to prevent invalid values
  const DaysInput = () => (
    <input
      type="number"
      value={daysBack}
      onChange={(e) => handleDaysBackChange(e.target.value)}
      min="1"
      className="w-20 px-2 py-1 text-sm border rounded"
      onBlur={(e) => {
        // Ensure minimum value on blur
        if (!e.target.value || parseInt(e.target.value) < 1) {
          handleDaysBackChange(1);
        }
      }}
    />
  );

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Sell Rate Analysis</h1>
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <label className="mr-2 text-xs font-medium text-gray-500">Days:</label>
              <div className="relative inline-flex items-center">
                <DaysInput />
              </div>
            </div>
            {hiddenProducts.length > 0 && (
              <button
                onClick={openUnhideModal}
                className="text-xs bg-gray-200 text-gray-700 rounded px-2 py-1 hover:bg-gray-300"
              >
                Unhide ({hiddenProducts.length})
              </button>
            )}
          </div>
        </div>
        {error && <p className="text-red-500">Error: {error}</p>}
        {isLoading ? (
          <p>Loading data...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <SortableHeader column="daily_rate" label="Daily Rate (kg)" />
                  <SortableHeader column="total_stock" label="Total Stock (kg)" />
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Extra Stock (kg)</th>
                  <SortableHeader column="days_until_out_of_stock" label="Days Until Out of Stock" />
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {productDataWithExtraStock.filter(product => !hiddenProducts.includes(product.sku_root)).map((product) => (
                  <React.Fragment key={product.sku_root}>
                    <tr 
                      className="cursor-pointer hover:bg-gray-100 group relative"
                      onClick={() => toggleExpandProduct(product)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                        {product.product_name}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            hideProduct(product.sku_root);
                          }}
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                        >
                          Hide
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <span className="font-bold">{product.daily_rate?.toFixed(2) || '0.00'}</span>
                        <br />
                        <span className="text-xs text-gray-500">
                          FBA: {product.daily_rate_fba?.toFixed(2) || '0.00'} | Non-FBA: {product.daily_rate_non_fba?.toFixed(2) || '0.00'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <span className="font-bold" data-tooltip-id={`stock-${product.sku_root}`}>{product.total_stock?.toFixed(2) || '0.00'}</span>
                        <br />
                        <span className="text-xs text-gray-500">
                          FBA: <span data-tooltip-id={`fba-${product.sku_root}`}>{product.fba_stock?.total?.toFixed(2) || '0.00'}</span> | 
                          Non-FBA: <span data-tooltip-id={`warehouse-${product.sku_root}`}>{product.warehouse_stock?.total?.toFixed(2) || '0.00'}</span>
                        </span>
                        <ReactTooltip
                          id={`stock-${product.sku_root}`}
                          place="top"
                          effect="solid"
                          className="z-50"  // Add this line
                        >
                          <div>
                            <strong>FBA Stock:</strong>
                            {product.fba_stock?.details?.map(item => (
                              <div key={item.sku}>{item.sku}: {item.quantity} ({item.kilograms?.toFixed(2) || '0.00'} kg)</div>
                            )) || 'No FBA stock data'}
                            <strong>Warehouse Stock:</strong>
                            {product.warehouse_stock?.details?.map(item => (
                              <div key={item.sku}>{item.sku}: {item.quantity} ({item.kilograms?.toFixed(2) || '0.00'} kg)</div>
                            )) || 'No warehouse stock data'}
                          </div>
                        </ReactTooltip>
                        <ReactTooltip
                          id={`fba-${product.sku_root}`}
                          place="top"
                          effect="solid"
                          className="z-50"  // Add this line
                        >
                          <div>
                            {product.fba_stock?.details?.map(item => (
                              <div key={item.sku}>{item.sku}: {item.quantity} ({item.kilograms?.toFixed(2) || '0.00'} kg)</div>
                            )) || 'No FBA stock data'}
                          </div>
                        </ReactTooltip>
                        <ReactTooltip
                          id={`warehouse-${product.sku_root}`}
                          place="top"
                          effect="solid"
                          className="z-50"  // Add this line
                        >
                          <div>
                            {product.warehouse_stock?.details?.map(item => (
                              <div key={item.sku}>{item.sku}: {item.quantity} ({item.kilograms?.toFixed(2) || '0.00'} kg)</div>
                            )) || 'No warehouse stock data'}
                          </div>
                        </ReactTooltip>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative">
                        {editingExtraStock.hasOwnProperty(product.sku_root) ? (
                          <div className="flex items-center">
                            <input
                              type="number"
                              value={editingExtraStock[product.sku_root]}
                              onChange={(e) => handleExtraStockChange(product.sku_root, e.target.value)}
                              className="w-20 px-2 py-1 border rounded"
                              onClick={(e) => e.stopPropagation()}
                            />
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleExtraStockSubmit(product.sku_root);
                              }}
                              className="ml-2 text-green-600 hover:text-green-800"
                              disabled={savingExtraStock[product.sku_root]}
                            >
                              {savingExtraStock[product.sku_root] ? '...' : '✓'}
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingExtraStock(prev => {
                                  const newState = { ...prev };
                                  delete newState[product.sku_root];
                                  return newState;
                                });
                              }}
                              className="ml-2 text-red-600 hover:text-red-800"
                            >
                              ✗
                            </button>
                          </div>
                        ) : (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingExtraStock(prev => ({
                                ...prev,
                                [product.sku_root]: extraStock[product.sku_root] || 0
                              }));
                            }}
                          >
                            {extraStock[product.sku_root] || 0}
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product.days_until_out_of_stock}
                      </td>
                    </tr>
                    {expandedProduct && expandedProduct.sku_root === product.sku_root && (
                      <tr>
                        <td colSpan="5" className="px-6 py-4">
                          <div className="h-96">
                            <Line
                              data={product.sell_rate_data}
                              options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                  y: {
                                    beginAtZero: true,
                                    title: {
                                      display: true,
                                      text: 'Kilograms Sold'
                                    }
                                  },
                                  x: {
                                    title: {
                                      display: true,
                                      text: 'Date'
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">SKU Root Analysis</h2>
          <div className="mb-4">
            <label htmlFor="skuRoot" className="block text-sm font-medium text-gray-700 mb-1">
              Select SKU Root
            </label>
            <div className="relative">
              <select
                id="skuRoot"
                value={selectedSkuRoot}
                onChange={handleSkuRootChange}
                className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm appearance-none"
              >
                <option value="">Select a SKU Root</option>
                {productData.map(product => (
                  <option key={product.sku_root} value={product.sku_root}>{product.sku_root}</option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        
          {chartData && (
            <div className="mt-4 h-96">
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Quantity Sold (30-day average)'
                      }
                    },
                    x: {
                      title: {
                        display: true,
                        text: 'Date'
                      }
                    }
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isUnhideModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Hidden Products
                </h3>
                <div className="mt-2">
                  <ul className="mt-2 space-y-2">
                    {hiddenProducts.map(skuRoot => (
                      <li key={skuRoot} className="flex justify-between items-center">
                        <span>{skuRoot}</span>
                        <button
                          onClick={() => unhideProduct(skuRoot)}
                          className="px-2 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 text-sm"
                        >
                          Unhide
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeUnhideModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SellRate;